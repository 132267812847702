import Vue from "vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/src/locale/pt";
import en from "vuetify/src/locale/en";
import es from "vuetify/src/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#009688',
        secondary: '#cddc39',
        accent: '#673ab7',
        attention: '#8d1911',
        error: '#f44336',
        warning: '#ffc107',
        info: '#607d8b',
        success: '#4caf50'
      }
    }
  },
  lang: {
    locales: { pt, en, es },
    current: 'pt'
  },
  icons: {
    iconfont: 'mdiSvg'
  }
});
