import faunadb, { values, Client, ExprVal, errors } from 'faunadb';
import Document = values.Document;
import {
  FaunaHttpErrorResponseContentExtension,
  FaunaPropertiesEvent
} from '@/helpers/data/fauna-types';
import { ExprArg, Replace } from 'faunadb/src/types/query';
import Ref = values.Ref;
import { logoutError } from '@/helpers/utils';
import { ERROR_LIST, ErrorItem } from '@/helpers/errors';
import Vue from 'vue';

const q = faunadb.query;

function handleFaunaError(faunaError: errors.FaunaHTTPError): ErrorItem {
  let error: ErrorItem = ERROR_LIST.GENERIC_ERROR_NO_EXISTS;
  if (faunaError.name == 'PermissionDenied') {
    error = ERROR_LIST.FAUNA_PERMISSION_DENIED;
  } else if (
    faunaError.name == 'BadRequest' &&
    faunaError.message === 'invalid ref'
  ) {
    error = ERROR_LIST.FAUNA_BAD_REQUEST_INVALID_REF;
  } else if (
    faunaError.name == 'BadRequest' &&
    faunaError.message === 'call error'
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorCause: FaunaHttpErrorResponseContentExtension =
      faunaError.requestResult.responseContent.errors[0];
    if (errorCause.cause[0].code === 'transaction aborted') {
      if (errorCause.cause[0].description === 'User does not exist.') {
        error = ERROR_LIST.SPACE_ADD_USER_DOES_NOT_EXIST;
      } else {
        error = ERROR_LIST.FAUNA_BAD_REQUEST_TRANSACTION_ABORTED;
      }
    } else {
      error = ERROR_LIST.FAUNA_BAD_REQUEST_FUNCTION_ERROR;
    }
  } else {
    Vue.prototype.$rollbar.error('Fauna Error not Mapped', faunaError);
  }
  error.errorStack = faunaError;
  if (error.action === 'logout') {
    logoutError(error);
    Vue.prototype.$rollbar.error(error);
    throw new Error('Forced logout.'); // this is needed to ensure the user is logged off and no other errors appear
  }
  return error;
}

async function queryDocument<T extends Document>(
  client: Client,
  collection: string,
  id: string
): Promise<T> {
  return new Promise((resolve, reject) => {
    client
      .query<T>(q.Get(q.Ref(q.Collection(collection), id)))
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        error = handleFaunaError(error);
        reject(error);
      });
  });
}

async function queryDocumentsByIndex<T extends Document[]>(
  client: Client,
  index: string,
  paginateOpts: Record<string, unknown>,
  term: ExprArg | ExprArg[]
): Promise<T> {
  return new Promise((resolve, reject) => {
    client
      .query<T>(
        q.Select(
          ['data'],
          q.Map(
            q.Paginate(q.Match(q.Index(index), term), paginateOpts),
            q.Lambda('X', q.Get(q.Var('X')))
          )
        )
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        error = handleFaunaError(error);
        reject(error);
      });
  });
}

async function queryAllDocumentsByIndex<T extends Document[]>(
  client: Client,
  index: string,
  term: ExprArg | ExprArg[]
): Promise<T> {
  return new Promise((resolve, reject) => {
    queryDocumentsByIndex<T>(client, index, {}, term)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        error = handleFaunaError(error);
        reject(error);
      });
  });
}

async function queryDocumentByIndex<T extends Document>(
  client: Client,
  index: string,
  term: ExprArg
): Promise<T> {
  return new Promise((resolve, reject) => {
    client
      .query<T>(q.Get(q.Match(q.Index(index), term)))
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        error = handleFaunaError(error);
        reject(error);
      });
  });
}

async function updateDocument<T extends Document>(
  client: Client,
  collection: string,
  id: string,
  data: Record<string, unknown>
): Promise<T> {
  return new Promise((resolve, reject) => {
    client
      .query<T>(q.Update(q.Ref(q.Collection(collection), id), { data: data }))
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        error = handleFaunaError(error);
        reject(error);
      });
  });
}

async function replaceDocument<T extends Document>(
  client: Client,
  collection: string,
  id: string,
  data: Record<string, unknown>
): Promise<T> {
  return new Promise((resolve, reject) => {
    client
      .query<T>(q.Replace(q.Ref(q.Collection(collection), id), { data: data }))
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        error = handleFaunaError(error);
        reject(error);
      });
  });
}

async function deleteDocument<T extends Document>(
  client: Client,
  collection: string,
  id: string
): Promise<T> {
  return new Promise((resolve, reject) => {
    client
      .query<T>(q.Delete(q.Ref(q.Collection(collection), id)))
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        error = handleFaunaError(error);
        reject(error);
      });
  });
}

async function createDocument<T extends Document>(
  client: Client,
  collection: string,
  data: Record<string, unknown>
): Promise<T> {
  return new Promise((resolve, reject) => {
    client
      .query<T>(q.Create(q.Collection(collection), { data: data }))
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        error = handleFaunaError(error);
        reject(error);
      });
  });
}

async function queryDocumentsByRef<T extends Document | Document[]>(
  client: Client,
  refs: Ref[]
): Promise<T> {
  return new Promise((resolve, reject) => {
    client
      .query<T>(q.Map(refs, q.Lambda('X', q.Get(q.Var('X')))))
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        error = handleFaunaError(error);
        reject(error);
      });
  });
}

async function callFunction<T>(
  client: Client,
  functionName: string,
  ...args: ExprVal[]
): Promise<T> {
  return new Promise((resolve, reject) => {
    client
      .query<T>(q.Call(q.Function(functionName), args))
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        error = handleFaunaError(error);
        reject(error);
      });
  });
}

async function getDocumentHistory<
  T extends Document[] | FaunaPropertiesEvent[]
>(client: Client, collection: string, id: string): Promise<T> {
  return new Promise((resolve, reject) => {
    client
      .query<T>(
        q.Select(
          'data',
          q.Paginate(q.Events(q.Ref(q.Collection(collection), id)))
        )
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        error = handleFaunaError(error);
        reject(error);
      });
  });
}

export {
  queryDocument,
  queryDocumentByIndex,
  queryDocumentsByIndex,
  queryAllDocumentsByIndex,
  queryDocumentsByRef,
  createDocument,
  updateDocument,
  replaceDocument,
  deleteDocument,
  getDocumentHistory,
  callFunction
};
