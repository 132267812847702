import {
  ActionContext,
  ActionTree,
  CommitOptions,
  DispatchOptions,
  GetterTree,
  Module,
  MutationTree,
  Store as VuexStore
} from 'vuex';

import { State as RootState } from '@/store';
import {
  dbCreateSportActivityProgram,
  dbPatchSportActivity,
  dbLoadSportActivityFromHorse,
  deleteFromFaunaDbCollection,
  dbPutSportActivity
} from '@/helpers/data/fauna-queries';
import Vue from 'vue';
import { ERROR_LIST, ErrorItem } from '@/helpers/errors';

export interface SportActivity {
  id: string;
  horseId?: string;
  activity: string;
  duration: string;
  observation: string;
  dateStart: string;
  professional?: string;
  done?: boolean;
  rating: number;
}

export interface ActivitiesData {
  all: SportActivity[];
}

export interface State {
  activities: Record<string, SportActivity[]>;
}

function initialState() {
  return {
    activities: {}
  };
}

// getters
export type Getters = {
  getSportActivitiesByHorseId(
    state: State
  ): (horseId: string) => SportActivity[] | null;
};

const getters: GetterTree<State, RootState> & Getters = {
  getSportActivitiesByHorseId: (state) => (horseId) => {
    let sportActivities: SportActivity[] = [];
    if (horseId in state.activities)
      sportActivities = state.activities[horseId];
    return sportActivities;
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  createActivity(
    state: S,
    payload: { horseId: string; activity: SportActivity }
  ): void;
  updateActivity(
    state: S,
    payload: { horseId: string; activity: SportActivity }
  ): void;
  deleteActivity(
    state: S,
    payload: { horseId: string; activityId: string }
  ): void;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    Object.assign(state, initialState());
  },

  updateCreateActivity(state, activity) {
    if (activity.horseId) {
      if (activity.horseId in state.activities) {
        Vue.set(state.activities, activity.horseId, activity);
      } else {
        throw 'Cannot update an object that does not exists';
      }
    } else throw 'HorseId Cannot be null';
  },
  createActivity(state, payload) {
    if (payload.horseId) {
      if (payload.horseId in state.activities) {
        state.activities[payload.horseId].push(payload.activity);
      } else {
        Vue.set(state.activities, payload.horseId, [payload.activity]);
      }
    } else throw 'HorseId Cannot be null';
  },
  updateActivity(state, payload) {
    if (payload.horseId) {
      if (payload.horseId in state.activities) {
        const activities = state.activities[payload.horseId];
        const index = activities.findIndex(
          (ac) => ac.id === payload.activity.id
        );
        activities[index].activity = payload.activity.activity;
        Vue.set(state.activities, payload.horseId, activities);
      } else {
        throw 'Cannot update an object that does not exists';
      }
    } else throw 'HorseId Cannot be null';
  },
  deleteActivity(state, payload) {
    if (payload.horseId && payload.activityId) {
      if (payload.horseId in state.activities) {
        const activities = state.activities[payload.horseId];
        const index = activities.findIndex(
          (ac) => ac.id === payload.activityId
        );
        activities.splice(index, 1);
        Vue.set(state.activities, payload.horseId, activities);
      }
    }
  }
};

// actions
export interface Actions {
  addSportActivityToHorse(
    { commit }: ActionContext<State, RootState>,
    payload: { horseId: string; activity: SportActivity }
  ): Promise<void>;

  loadSportActivityFromHorse(
    { getters, commit }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<SportActivity[] | null>;

  patchSportActivity(
    { commit }: ActionContext<State, RootState>,
    payload: { activity: SportActivity }
  ): Promise<SportActivity | ErrorItem>;

  putSportActivity(
    { commit }: ActionContext<State, RootState>,
    payload: { activity: SportActivity }
  ): Promise<SportActivity | ErrorItem>;

  deleteSportActivity(
    { commit }: ActionContext<State, RootState>,
    payload: { horseId: string; sportActivityId: string }
  ): Promise<void | ErrorItem>;
}

const actions: ActionTree<State, RootState> & Actions = {
  addSportActivityToHorse({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dbCreateSportActivityProgram(payload.horseId, payload.activity).then(
        (obj) => {
          commit('createActivity', {
            activity: obj.activity,
            horseId: obj.activity.horseId
          });
          dispatch(
            'FeedModule/addFeedInHorse',
            {
              horseId: obj.feed.horse?.id,
              feed: obj.feed
            },
            { root: true }
          ).catch((error) => {
            Vue.prototype.$rollbar.error(error);
            reject(error);
          });
          commit(
            'HorseModule/addNotification',
            {
              horseId: obj.notification.horseId,
              notification: obj.notification,
              module: obj.notification.category
            },
            { root: true }
          );
          resolve();
        }
      );
    });
  },
  loadSportActivityFromHorse({ getters, commit }, horseId) {
    return new Promise((resolve, reject) => {
      const activities = getters.getSportActivitiesByHorseId(horseId);
      if (!activities || !activities.length) {
        dbLoadSportActivityFromHorse(horseId)
          .then((responseActivities) => {
            if (responseActivities && responseActivities.length) {
              responseActivities.forEach((activity) => {
                commit('createActivity', {
                  activity: activity,
                  horseId: activity.horseId
                });
              });
            }
            resolve(responseActivities);
          })
          .catch(() => {
            reject(null);
          });
      } else {
        resolve(activities);
      }
    });
  },
  patchSportActivity({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dbPatchSportActivity(payload.activity)
        .then((updatedSportActivity) => {
          commit('updateActivity', {
            activity: updatedSportActivity,
            horseId: updatedSportActivity.horseId
          });
          resolve(updatedSportActivity);
        })
        .catch((error) => {
          Vue.prototype.$rollbar.errorr(error);
          reject(ERROR_LIST.SPORT_ACTIVITY_UPDATE_FAIL);
        });
    });
  },
  putSportActivity({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dbPutSportActivity(payload.activity)
        .then((updatedSportActivity) => {
          commit('updateActivity', {
            activity: updatedSportActivity,
            horseId: updatedSportActivity.horseId
          });
          resolve(updatedSportActivity);
        })
        .catch((error) => {
          Vue.prototype.$rollbar.errorr(error);
          reject(ERROR_LIST.SPORT_ACTIVITY_UPDATE_FAIL);
        });
    });
  },
  deleteSportActivity(
    { commit },
    payload: { horseId: string; sportActivityId: string }
  ) {
    return new Promise((resolve, reject) => {
      deleteFromFaunaDbCollection(payload.sportActivityId, 'activities')
        .then(() => {
          commit('deleteActivity', {
            activityId: payload.sportActivityId,
            horseId: payload.horseId
          });
          resolve();
        })
        .catch(() => {
          Vue.prototype.$rollbar.error(
            `Error: Not able to delete sport activity(${payload.sportActivityId})`
          );
          reject(ERROR_LIST.SPORT_ACTIVITY_DELETE_FAIL);
        });
    });
  }
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const SportModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
