import {
  ActionContext,
  ActionTree,
  CommitOptions,
  DispatchOptions,
  GetterTree,
  Module,
  MutationTree,
  Store as VuexStore
} from "vuex";

import { State as RootState } from "@/store";
import { PLAN, Plan, Space, SPACE_STATUS } from "@/store/modules/space";
import { dbEditUser, login } from "@/helpers/data/fauna-queries";
import { ERROR_LIST, ErrorItem } from "@/helpers/errors";
import Tawk from "@/plugins/tawk";
import Vue from "vue";

export interface Role {
  name: string;
  i18nCode: string;
}

export function createRole(name: string): Role {
  return {
    name: name,
    i18nCode: 'role.' + name
  };
}

export interface User {
  id: string;
  name: string;
  dob: string;
  email: string;
  phone?: string;
  avatar?: string;
  spaces: Space[];
  freePlan: string | null; //Time ISO
}

//state
export interface State {
  user: User | null;
  currentSpace: Space | null;
}

function initialState() {
  return {
    user: null,
    currentSpace: null
  };
}

// getters
export type Getters = {
  getUser(state: State): User | null;
  getSpace(state: State): Space | null;
  getUserRole(state: State): Role | null;
};

const getters: GetterTree<State, RootState> & Getters = {
  getUser: (state) => {
    return state.user;
  },
  getSpace: (state) => {
    return state.currentSpace;
  },
  getUserRole: (state) => {
    if (state.currentSpace) return state.currentSpace.role;
    else return null;
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  login(state: S, user: User): void;
  logout(state: S): void;
  setCurrentSpace(state: S, space: Space): void;
  setFreePlan(state: S): void;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    // acquire initial state
    Object.assign(state, initialState());
  },
  login(state, user) {
    state.user = user;
  },
  logout(state) {
    state.user = null;
    state.currentSpace = null;
  },
  setCurrentSpace(state, space) {
    state.currentSpace = space;
  },
  setFreePlan(state) {
    if (state.user) {
      state.user.freePlan = new Date().toISOString();
    }
  }
};

// actions
export interface Actions {
  login(
    { commit, dispatch }: ActionContext<State, RootState>,
    payload: { email: string; password: string }
  ): Promise<{ status: string; space?: Space }>;

  updateUser(
    { commit }: ActionContext<State, RootState>,
    payload: { user: User }
  ): Promise<User | ErrorItem>;
}

const actions: ActionTree<State, RootState> & Actions = {
  login({ commit, dispatch }, { email, password }) {
    return new Promise((resolve, reject) => {
      login(email, password)
        .then((user) => {
          if (user) {
            commit('login', user);
            Tawk.updateChatUser(user.name, user.email);
            Vue.prototype.$rollbar.configure({
              payload: {
                person: {
                  id: user.id,
                  username: user.name
                }
              }
            });
            dispatch('SpaceModule/loadSpaces', null, { root: true })
              .then((spaces) => {
                user.spaces = spaces;
                if (user.spaces.length >= 1) {
                  resolve({ status: 'spaceHome', space: user.spaces[0] });
                } else {
                  const spaceNew = {
                    id: '',
                    name: user.name,
                    role: createRole('owner'),
                    owner: user.name,
                    horseNumber: 0,
                    stableNumber: 0,
                    userNumber: 0,
                    plan: {
                      horse: 99,
                      stable: 99,
                      user: 99,
                      type: PLAN.FREE,
                      price: 0
                    } as Plan,
                    status: SPACE_STATUS.INACTIVE
                  } as Space;
                  dispatch(
                    'SpaceModule/createSpace',
                    {
                      space: spaceNew,
                      avatar: null,
                      payment: null
                    },
                    { root: true }
                  )
                    .then((spaceCreated) => {
                      resolve({
                        status: 'spaceHome',
                        space: spaceCreated.space
                      });
                    })
                    .catch((error) => {
                      Vue.prototype.$rollbar.error(error);
                      reject(ERROR_LIST.ACCOUNT_WITH_PROBLEMS);
                    });
                }
              })
              .catch((error) => {
                Vue.prototype.$rollbar.error(error);
                reject(ERROR_LIST.ACCOUNT_WITH_PROBLEMS);
              });
          } else {
            reject(ERROR_LIST.ACCOUNT_WITH_PROBLEMS);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateUser({ commit }, payload: { user: User }): Promise<User | ErrorItem> {
    return new Promise((resolve, reject) => {
      dbEditUser(payload.user)
        .then(() => {
          const newUser = Object.assign({}, payload.user);
          commit('login', newUser);
          resolve(newUser);
        })
        .catch((error) => {
          Vue.prototype.$rollbar.error(error);
          reject(ERROR_LIST.USER_UPDATE_FAIL);
        });
    });
  }
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const UserModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
