import { SystemEvent } from "@/store/modules/systemevent";
import {
  CalendarEventFauna,
  CalendarEventFaunaDTO,
  ExamGlandersEIAFauna,
  ExamGlandersEIAFaunaDTO,
  FeedItemFauna,
  FoodFauna,
  HealthReportFauna,
  HealthReportFaunaDTO,
  HorseDTO,
  HorseFauna,
  HorseShoeDTO,
  HorseShoeFauna,
  NotificationFauna,
  SpaceAllRefFauna,
  SpacesFauna,
  SportActivityFauna,
  SportActivityFaunaDTO,
  StableDTO,
  StableFauna,
  SystemEventFauna,
  UserDbData,
  VaccineFauna,
  VaccineFaunaDTO,
  VermifugeFauna,
  VermifugeFaunaDTO
} from "@/helpers/data/fauna-types";
import { getModuleType } from "@/store/constants";
import store from "@/store";
import { Notification } from "@/helpers/notifications";
import { FeedItem } from "@/store/modules/feed";
import { Diet } from "@/store/modules/food";
import { Stable } from "@/store/modules/stable";
import { HorseShoe } from "@/store/modules/horseShoe";
import faunadb, { values } from "faunadb";
import { createRole, User } from "@/store/modules/user";
import { Space, SPACE_STATUS } from "@/store/modules/space";
import { ExamGlandersEIA, HealthReport, Vaccine, Vermifuge } from "@/store/modules/health";
import { Horse } from "@/store/modules/horse";
import { SportActivity } from "@/store/modules/sport";
import { CalendarEvent } from "@/store/modules/calendar";
import Expr from "faunadb/src/types/Expr";
import FaunaTime = values.FaunaTime;
import FaunaDate = values.FaunaDate;

export const eventsConvert = function (
  fauna: SystemEventFauna[]
): SystemEvent[] {
  return fauna.map((item: SystemEventFauna) => {
    return eventConvert(item);
  });
};

export const eventConvert = function (item: SystemEventFauna): SystemEvent {
  return {
    id: item.ref.id,
    name: item.data.name,
    description: item.data.description,
    category: getModuleType(item.data.category),
    subCategory: item.data.subCategory, //TODO derive as a sub module?
    start: item.data.start.date,
    end: item.data.end.date,
    action: item.data.action, //TODO should be a list of function how to do it properly?
    actionData: item.data.actionData,
    userName: item.data.userName,
    horse: item.data.horseRef
      ? store.getters['HorseModule/getById'](item.data.horseRef.id)
      : null,
    stable: item.data.stableRef
      ? store.getters['StableModule/getById'](item.data.stableRef.id)
      : null,
    originated: item.data.originatedRef
      ? {
          collection: item.data.originatedRef?.collection?.id || null,
          id: item.data.originatedRef?.id
        }
      : undefined
  };
};

export const notificationsConvert = function (
  fauna: NotificationFauna[]
): Notification[] {
  return fauna.map((item: NotificationFauna) => {
    return notificationConvert(item);
  });
};

export const notificationConvert = function (
  item: NotificationFauna
): Notification {
  return {
    id: item.ref.id,
    category: getModuleType(item.data.category),
    subCategory: item.data.subCategory, //TODO derive as a sub module?
    start: item.data.start.date,
    end: item.data.end.date,
    read: item.data.read,
    notificationPeriod: item.data.notification,
    userName: item.data.userName,
    horseId: item.data.horseRef ? item.data.horseRef.id : null,
    stableId: item.data.stableRef ? item.data.stableRef.id : null,
    userId: item.data.userRef ? item.data.userRef.id : null,
    originated: item.data.originatedRef
      ? {
          collection: item.data.originatedRef?.collection?.id || null,
          id: item.data.originatedRef?.id
        }
      : undefined
  };
};

export const feedsConvert = function (fauna: FeedItemFauna[]): FeedItem[] {
  return fauna.map((item: FeedItemFauna) => {
    return feedConvert(item);
  });
};

export const feedConvert = function (item: FeedItemFauna): FeedItem {
  return {
    horse: store.getters['HorseModule/getById'](item.data.horseId.id),
    title: item.data.title,
    titleData: item.data.titleData ? item.data.titleData : null,
    subTitle: item.data.subTitle,
    subTitleData: item.data.subTitleData ? item.data.subTitleData : null,
    userName: item.data.userName,
    type: getModuleType(item.data.type),
    timestamp: new Date(Number(item.ts) / 1000)
  };
};

export const foodsConvert = function (fauna: FoodFauna[]): Diet[] {
  return fauna.map((item: FoodFauna) => {
    return foodConvert(item);
  });
};

export const foodConvert = function (item: FoodFauna): Diet {
  return {
    id: item.ref.id,
    horseId: item.data.horse.id,
    dateStart: item.data.dateStart.date,
    dateEnd: item.data.dateEnd ? item.data.dateEnd.date : null,
    foodItems: item.data.foodItems
  };
};

export const stableConvert = function (item: StableFauna): Stable {
  return {
    id: item.ref.id,
    horses: [],
    name: item.data.name,
    address: item.data.address,
    phone: item.data.phone,
    professionals: item.data.professionals
  };
};

export const stableFaunaConvert = function (item: Stable): StableDTO {
  const newHorseRef: Expr[] = [];
  const q = faunadb.query;
  item.horses.forEach((horseId) => {
    newHorseRef.push(q.Ref(q.Collection('horses'), horseId));
  });
  return {
    name: item.name,
    address: item.address,
    phone: item.phone,
    professionals: item.professionals,
    horsesRef: newHorseRef
  };
};

export const horseShoesConvert = function (
  fauna: HorseShoeFauna[]
): HorseShoe[] {
  return fauna.map((item: HorseShoeFauna) => {
    return horseShoeConvert(item);
  });
};

export const healthReportsConvert = function (
  fauna: HealthReportFauna[]
): HealthReport[] {
  return fauna.map((item: HealthReportFauna) => {
    return healthReportConvert(item);
  });
};

export const vermifugesConvert = function (
  fauna: VermifugeFauna[]
): Vermifuge[] {
  return fauna.map((item: VermifugeFauna) => {
    return vermifugeConvert(item);
  });
};

export const vaccinesConvert = function (fauna: VaccineFauna[]): Vaccine[] {
  return fauna.map((item: VaccineFauna) => {
    return vaccineConvert(item);
  });
};

export const ExamGlandersEIAListConvert = function (
  fauna: ExamGlandersEIAFauna[]
): ExamGlandersEIA[] {
  return fauna.map((item: ExamGlandersEIAFauna) => {
    return ExamGlandersEIAConvert(item);
  });
};

export const SportActivitiesConvert = function (
  fauna: SportActivityFauna[]
): SportActivity[] {
  return fauna.map((item: SportActivityFauna) => {
    return SportActivityConvert(item);
  });
};

export const CalendarEventsConvert = function (
  fauna: CalendarEventFauna[]
): CalendarEvent[] {
  return fauna.map((item: CalendarEventFauna) => {
    return CalendarEventConvert(item);
  });
};

export const horseShoeConvert = function (item: HorseShoeFauna): HorseShoe {
  return {
    id: item.ref.id,
    horseId: item.data.horseRef.id,
    dateStart: item.data.dateStart.date.toISOString(),
    type: item.data.type,
    dateEnd: item.data.dateEnd ? item.data.dateEnd.date.toISOString() : null,
    datePredictableEnd: item.data.datePredictableEnd.date.toISOString(),
    observations: item.data.observations
  };
};

export const horseShoeFaunaConvert = function (item: HorseShoe): HorseShoeDTO {
  return {
    dateStart: new FaunaTime(new Date(item.dateStart)),
    type: item.type,
    dateEnd: item.dateEnd ? new FaunaTime(new Date(item.dateEnd)) : null,
    datePredictableEnd: new FaunaTime(new Date(item.datePredictableEnd)),
    observations: item.observations
  };
};

export const userFaunaConvert = function (item: User): UserDbData {
  return {
    name: item.name,
    email: item.email,
    avatar: item.avatar,
    phone: item.phone,
    dob: item.dob,
    freePlan: item.freePlan ? new FaunaTime(new Date(item.freePlan)) : null
  };
};

export const horseFaunaConvert = function (item: Horse): HorseDTO {
  return {
    name: item.name,
    avatar: item.avatar,
    sex: item.sex,
    category: item.category,
    pelage: item.pelage,
    owner: item.owner,
    passport: item.passport,
    box: item.box,
    chip: item.chip,
    genealogyNumber: item.genealogyNumber,
    born: {
      date:
        item.born && item.born.date
          ? new FaunaDate(new Date(item.born.date))
          : null,
      country: item.born && item.born.country ? item.born.country : ''
    },
    federation: item.federation,
    stallionNumber: item.stallionNumber,
    height: item.height,
    weight: item.weight,
    race: item.race,
    genealogy: item.genealogy,
    photos: item.photos,
    weights: item.weights
  };
};

export const horseConvert = function (item: HorseFauna): Horse {
  const bornDate = { date: '', country: '' };
  if (item.data.born) {
    bornDate.country = item.data.born.country;
    if (item.data.born.date) {
      if (typeof item.data.born.date === 'object') {
        bornDate.date = item.data.born.date.date.toISOString();
      } else {
        bornDate.date = new Date(item.data.born.date).toISOString();
      }
    }
  }
  return {
    id: item.ref.id,
    name: item.data.name,
    avatar: item.data.avatar,
    sex: item.data.sex,
    category: item.data.category,
    pelage: item.data.pelage,
    owner: item.data.owner,
    passport: item.data.passport,
    box: item.data.box,
    chip: item.data.chip,
    genealogyNumber: item.data.genealogyNumber,
    born: bornDate,
    federation: item.data.federation,
    stallionNumber: item.data.stallionNumber,
    height: item.data.height,
    weight: item.data.weight,
    race: item.data.race,
    genealogy: item.data.genealogy,
    photos: item.data.photos,
    weights: item.data.weights,
    notifications: null
  };
};

export const healthReportConvert = function (
  item: HealthReportFauna
): HealthReport {
  return {
    id: item.ref.id,
    horseId: item.data.horseRef.id,
    dateStart: item.data.dateStart.date.toISOString(),
    dateEnd: item.data.dateEnd ? item.data.dateEnd.date.toISOString() : null,
    type: item.data.type,
    lastEntry: item.data.lastEntry,
    entries: item.data.entries,
    files: item.data.files
  };
};

export const vermifugeConvert = function (item: VermifugeFauna): Vermifuge {
  return {
    id: item.ref.id,
    horseId: item.data.horseRef.id,
    dateStart: item.data.dateStart.date.toISOString(),
    dateEnd: item.data.dateEnd.date.toISOString(),
    drug: item.data.drug,
    activePrinciple: item.data.activePrinciple,
    doctor: item.data.doctor
  };
};

export const vaccineConvert = function (item: VaccineFauna): Vaccine {
  return {
    id: item.ref.id,
    horseId: item.data.horseRef.id,
    dateStart: item.data.dateStart.date.toISOString(),
    dateEnd: item.data.dateEnd.date.toISOString(),
    product: item.data.product,
    batchNumber: item.data.batchNumber,
    protectedAgainst: item.data.protectedAgainst,
    doctor: item.data.doctor
  };
};

export const ExamGlandersEIAConvert = function (
  item: ExamGlandersEIAFauna
): ExamGlandersEIA {
  return {
    id: item.ref.id,
    horseId: item.data.horseRef.id,
    dateStart: item.data.dateStart.date.toISOString(),
    dateEnd: item.data.dateEnd.date.toISOString(),
    laboratory: item.data.laboratory,
    examNumber: item.data.examNumber,
    doctor: item.data.doctor
  };
};

export const SportActivityConvert = function (
  item: SportActivityFauna
): SportActivity {
  return {
    id: item.ref.id,
    horseId: item.data.horseRef.id,
    activity: item.data.activity,
    duration: item.data.duration,
    observation: item.data.observation,
    dateStart: item.data.dateStart.date.toISOString(),
    professional: item.data.professional,
    done: item.data.done,
    rating: item.data.rating
  };
};

export const CalendarEventConvert = function (
  item: CalendarEventFauna
): CalendarEvent {
  return {
    id: item.ref.id,
    horseId: item.data.horseRef.id,
    referenceId: item.data.featureRef.id,
    name: item.data.name,
    moduleName: item.data.moduleName,
    feature: item.data.feature,
    editable: item.data.editable,
    color: item.data.color,
    icon: item.data.icon,
    calendarMap: item.data.calendarMap,
    end: item.data.end?.date.toISOString(),
    start: item.data.start.date.toISOString(),
    details: item.data.details,
    timed: item.data.timed
  };
};

export const healthReportFaunaConvert = function (
  item: HealthReport
): HealthReportFaunaDTO {
  return {
    dateStart: new FaunaTime(new Date(item.dateStart)),
    dateEnd: item.dateEnd ? new FaunaTime(new Date(item.dateEnd)) : null,
    type: item.type,
    lastEntry: item.lastEntry,
    entries: item.entries,
    files: item.files
  };
};

export const vermifugeFaunaConvert = function (
  item: Vermifuge
): VermifugeFaunaDTO {
  return {
    dateStart: new FaunaTime(new Date(item.dateStart)),
    dateEnd: new FaunaTime(new Date(item.dateEnd)),
    drug: item.drug,
    activePrinciple: item.activePrinciple,
    doctor: item.doctor
  };
};

export const vaccineFaunaConvert = function (item: Vaccine): VaccineFaunaDTO {
  return {
    dateStart: new FaunaTime(new Date(item.dateStart)),
    dateEnd: new FaunaTime(new Date(item.dateEnd)),
    product: item.product,
    batchNumber: item.batchNumber,
    protectedAgainst: item.protectedAgainst,
    doctor: item.doctor
  };
};

export const ExamGlandersEIAFaunaConvert = function (
  item: ExamGlandersEIA
): ExamGlandersEIAFaunaDTO {
  return {
    dateStart: new FaunaTime(new Date(item.dateStart)),
    dateEnd: new FaunaTime(new Date(item.dateEnd)),
    laboratory: item.laboratory,
    examNumber: item.examNumber,
    doctor: item.doctor
  };
};

export const SportActivityFaunaConvert = function (
  item: SportActivity
): SportActivityFaunaDTO {
  return {
    activity: item.activity,
    duration: item.duration,
    observation: item.observation,
    dateStart: new FaunaTime(new Date(item.dateStart)),
    professional: item.professional,
    done: item.done,
    rating: item.rating
  };
};

export const CalendarEventFaunaConvert = function (
  item: CalendarEvent
): CalendarEventFaunaDTO {
  return {
    name: item.name,
    moduleName: item.moduleName,
    feature: item.feature,
    editable: item.editable,
    color: item.color,
    icon: item.icon,
    calendarMap: item.calendarMap,
    end: item.end ? new FaunaTime(new Date(item.end)) : undefined,
    start: new FaunaTime(new Date(item.start)),
    details: item.details,
    timed: item.timed
  };
};

export const spacesConvert = function (fauna: SpacesFauna): Space[] {
  return fauna.spaces.map((item: SpaceAllRefFauna, i: number) => {
    return {
      id: item.space.ref.id,
      name: item.space.data.name,
      avatar:
        typeof item.space.data.avatar === 'string'
          ? item.space.data.avatar
          : item.space.data.avatar.url,
      owner: item.owner,
      role: createRole(fauna.user_roles[i].data.role),
      createdAt: item.space.data.createdAt.date.toISOString(),
      horseNumber: item.space.data.horseNumber,
      stableNumber: item.space.data.stableNumber,
      userNumber: item.space.data.userNumber ? item.space.data.userNumber : 1,
      plan: item.space.data.plan,
      status: item.space.data.status
        ? item.space.data.status
        : SPACE_STATUS.ACTIVE,
      cancelAt: item.space.data.cancelAt
        ? item.space.data.cancelAt.date.toISOString()
        : undefined,
      payment: item.space.data.payment ? item.space.data.payment : undefined
    };
  });
};
