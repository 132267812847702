import {
  Store as VuexStore,
  GetterTree,
  MutationTree,
  CommitOptions,
  DispatchOptions,
  Module,
  ActionContext,
  ActionTree
} from 'vuex';

import { State as RootState } from '@/store';
import {
  createFoodForHorses,
  loadFoodActual,
  loadFoodHistory
} from '@/helpers/data/fauna-queries';
import Vue from 'vue';
import { i18n } from '@/i18n';
import dayjs from 'dayjs';

export interface FoodDetailComponentType {
  name: string;
  i18nCodeName: string;
}

export interface FoodDetailComponent {
  ETHER: FoodDetailComponentType;
  PROTEIN: FoodDetailComponentType;
}

export const FOOD_DETAIL_COMPONENTS: FoodDetailComponent = Object.freeze({
  ETHER: {
    name: 'ration',
    i18nCodeName: 'food-batch-card.food-details.ether'
  },
  PROTEIN: {
    name: 'supplement',
    i18nCodeName: 'food-batch-card.food-details.protein'
  }
});

export interface FoodDetail {
  component: string;
  description: string;
}

export interface FoodItem {
  id: number;
  category: string;
  brand?: string;
  avatar?: string;
  name: string;
  quantity: number;
  qtdUnit: string;
  frequency: number;
  freqPeriod: string;
  dateEnd?: string;
  detail?: FoodDetail[];
}

export interface Diet {
  id: string;
  horseId?: string;
  dateStart: Date; //TODO Change to string ISO8601
  dateEnd?: Date | null; //TODO Change to string ISO8601
  foodItems: FoodItem[];
}

export interface DietData {
  current: Diet;
  history: Diet[] | null;
}

export interface State {
  diets: Record<string, DietData>;
}

function initialState() {
  return {
    diets: {}
  };
}

// getters
export type Getters = {
  getByHorseId(state: State): (horseId: string) => DietData | null;
  getHistoryByHorseId(state: State): (horseId: string) => Diet[] | null;
  getCurrentByHorseId(state: State): (horseId: string) => Diet | null;
  getFoodQtdAndFrequency(state: State): (foodItem: FoodItem) => string;
};

const getters: GetterTree<State, RootState> & Getters = {
  getByHorseId: (state) => (horseId) => {
    let diets = null;
    if (horseId in state.diets) diets = state.diets[horseId];
    return diets;
  },
  getHistoryByHorseId: (state) => (horseId) => {
    let diets = null;
    if (horseId in state.diets) diets = state.diets[horseId].history;
    return diets;
  },
  getCurrentByHorseId: (state) => (horseId) => {
    let diet = null;
    if (horseId in state.diets) diet = state.diets[horseId].current;
    return diet;
  },
  getFoodQtdAndFrequency: () => (food) => {
    let qtdAndFrequency = '';
    if (food.quantity) {
      qtdAndFrequency = qtdAndFrequency + ' ' + food.quantity;
    }
    if (food.qtdUnit) {
      qtdAndFrequency = qtdAndFrequency + ' ' + food.qtdUnit;
    }
    if (food.frequency) {
      qtdAndFrequency = qtdAndFrequency + ' - ' + food.frequency;
    }
    if (food.freqPeriod) {
      qtdAndFrequency =
        qtdAndFrequency +
        ' ' +
        i18n.tc('food-batch-card.times', food.frequency) +
        ' ' +
        i18n.tc(food.freqPeriod);
      i18n.tc(food.freqPeriod);
    }
    if (
      food.category === 'food-batch-card.categories.supplement' &&
      food.dateEnd
    ) {
      qtdAndFrequency =
        qtdAndFrequency +
        ' - ' +
        i18n.tc('food-batch-card.end-date') +
        ' ' +
        dayjs(food.dateEnd).format('L');
    }
    return qtdAndFrequency;
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  create(state: S, diet: Diet): void;
  createHistory(state: S, payload: { horseId: string; diets: Diet[] }): void;
  updateCurrent(state: S, diet: Diet): void;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    Object.assign(state, initialState());
  },
  create(state, diet) {
    if (diet.horseId) {
      Vue.set(state.diets, diet.horseId, { current: diet, history: null });
    } else throw 'HorseId Cannot be null';
  },
  createHistory(state, payload) {
    if (payload.horseId) {
      if (payload.horseId in state.diets) {
        let history = state.diets[payload.horseId].history;
        if (!history) {
          history = [];
        }
        history.push(...payload.diets);
      } else {
        Vue.set(state.diets, payload.horseId, {
          current: null,
          history: payload.diets
        });
      }
    } else throw 'HorseId Cannot be null';
  },
  updateCurrent(state, diet) {
    if (diet.horseId) {
      if (diet.horseId in state.diets) {
        const oldCurrent = state.diets[diet.horseId].current;
        oldCurrent.dateEnd = diet.dateStart;
        let history = state.diets[diet.horseId].history;
        if (!history) {
          history = [];
        }
        history.push(oldCurrent);
        state.diets[diet.horseId].current = diet;
      } else {
        state.diets[diet.horseId] = { current: diet, history: [] };
      }
    } else throw 'HorseId Cannot be null';
  }
};

// actions
export interface Actions {
  addDietToHorses(
    { commit }: ActionContext<State, RootState>,
    payload: { horsesId: string[]; diet: Diet }
  ): Promise<void>;

  loadActualDietFromHorse(
    { getters, commit }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<Diet | null>;

  loadDietFromHorse(
    { getters, commit, dispatch }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<DietData | null>;
}

const actions: ActionTree<State, RootState> & Actions = {
  addDietToHorses({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const loadAllHorseFoods: Promise<DietData>[] = [];
      payload.horsesId.forEach((horseId) => {
        loadAllHorseFoods.push(dispatch('loadActualDietFromHorse', horseId));
      });
      createFoodForHorses(payload.horsesId, payload.diet)
        .then((obj) => {
          Promise.all(loadAllHorseFoods).then(() => {
            obj.diets.forEach((diet) => {
              commit('updateCurrent', diet);
            });
            obj.feeds.forEach((feed) => {
              dispatch(
                'FeedModule/addFeedInHorse',
                {
                  horseId: feed.horse?.id,
                  feed: feed
                },
                { root: true }
              ).catch((error) => Vue.prototype.$rollbar.error(error));
            });
            obj.notifications.forEach((item) => {
              commit(
                'HorseModule/addNotification',
                {
                  horseId: item.horseId,
                  notification: item,
                  module: item.category
                },
                { root: true }
              );
            });
            obj.systemEvents.forEach((item) => {
              commit('SystemEventModule/add', item, { root: true });
            });
            resolve();
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadActualDietFromHorse({ getters, commit }, horseId) {
    return new Promise((resolve, reject) => {
      const diet = getters.getCurrentByHorseId(horseId);
      if (!diet) {
        loadFoodActual(horseId)
          .then((dietNew) => {
            if (dietNew) commit('create', dietNew);
            resolve(dietNew);
          })
          .catch(() => {
            reject(null);
          });
      } else resolve(diet);
    });
  },
  loadDietFromHorse({ getters, commit, dispatch }, horseId) {
    return new Promise((resolve, reject) => {
      const diet = getters.getByHorseId(horseId);
      if (!diet || !diet.history) {
        dispatch('loadActualDietFromHorse', horseId)
          .then((dietActual) => {
            loadFoodHistory(horseId)
              .then((dietsHistory) => {
                if (dietsHistory && dietsHistory.length)
                  commit('createHistory', {
                    horseId: horseId,
                    diets: dietsHistory
                  });
                resolve({
                  current: dietActual,
                  history: dietsHistory
                });
              })
              .catch(() => {
                reject(null);
              });
          })
          .catch(() => {
            reject(null);
          });
      } else resolve(diet);
    });
  }
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const FoodModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
