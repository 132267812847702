import {
  ActionContext,
  ActionTree,
  Store as VuexStore,
  GetterTree,
  MutationTree,
  CommitOptions,
  DispatchOptions,
  Module,
  Dispatch,
  Commit
} from 'vuex';

import { State as RootState } from '@/store';
import { Role } from '@/store/modules/user';
import { ERROR_LIST, ErrorItem } from '@/helpers/errors';
import {
  editSpace,
  loadHorses,
  loadStables,
  loadUserSpaces,
  secret
} from '@/helpers/data/fauna-queries';
import { Stable } from '@/store/modules/stable';
import { StableFaunaData } from '@/helpers/data/fauna-types';
import { upload } from '@/helpers/image/imagekit-helpers';
import { loadNotifications } from '@/helpers/notifications';
import axios, { AxiosError } from 'axios';
import { CHECK_SPACE_URL, CREATE_SPACE_URL } from '@/store/constants';
import { Payment } from '@/helpers/payment/queries';
import Vue from 'vue';

//internal functions

const loadStablesDetails = function (
  rootGetters: Getters,
  commit: Commit,
  dispatch: Dispatch,
  stableFaunaData: StableFaunaData
): Promise<void> {
  return new Promise((resolve) => {
    const stable: Stable = {
      id: stableFaunaData.id,
      name: stableFaunaData.name,
      address: stableFaunaData.address,
      phone: stableFaunaData.phone,
      professionals: stableFaunaData.professionals,
      horses: []
    };
    commit('StableModule/create', stable, { root: true });
    loadHorses(stableFaunaData.horsesRef).then((horses) => {
      const horseLoaded = new Promise<void>((resolve) => {
        if (horses && horses.length > 0) {
          horses.forEach((horse, index, array) => {
            commit('HorseModule/create', horse, { root: true });
            dispatch(
              'StableModule/addHorse',
              {
                stableId: stable.id,
                horse: horse
              },
              { root: true }
            ).then(() => {
              if (index === array.length - 1) resolve();
            });
          });
        } else {
          resolve();
        }
      });
      horseLoaded.then(() => {
        resolve();
      });
    });
  });
};

const checkSpace = function (commit: Commit, space: Space): void {
  if (
    (space.status === SPACE_STATUS.ACTIVE &&
      space.plan &&
      space.plan.type === PLAN.FREE) ||
    (space.status === SPACE_STATUS.INACTIVE &&
      !space.payment &&
      space.plan &&
      space.plan.type === PLAN.PAID) ||
    space.status === SPACE_STATUS.PENDING_CANCELLATION
  ) {
    axios
      .patch<{ space: Space; status: 'updated' | 'no' }>(CHECK_SPACE_URL, {
        spaceId: space.id
      })
      .then((res) => {
        if (res.data.status === 'updated') {
          commit('updateSpace', { spaceId: space.id, space: res.data.space });
        }
      });
  }
};

export enum PLAN {
  FREE = 'free',
  PAID = 'paid'
}

export enum SPACE_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CANCELED = 'canceled',
  PENDING_CANCELLATION = 'pending_cancellation'
}

export enum PAYMENT_STATUS {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  CLOSED = 'closed',
  STOPPED = 'stopped'
}

export interface Plan {
  horse: number;
  stable: number;
  user: number;
  type: PLAN.FREE | PLAN.PAID | null;
  price: number;
}

export interface Space {
  id: string;
  name: string;
  avatar?: string;
  role: Role;
  owner: string;
  horseNumber: number;
  stableNumber: number;
  userNumber: number;
  createdAt?: string;
  plan: Plan;
  status: SPACE_STATUS;
  cancelAt?: string;
  payment?: {
    status: PAYMENT_STATUS;
    galaxPayId: number;
  };
}

//state
export interface State {
  spaces: Space[];
}

function initialState() {
  return {
    spaces: []
  };
}

// getters
export type Getters = {
  getById(state: State): (id: string) => Space | undefined;
};

const getters: GetterTree<State, RootState> & Getters = {
  getById: (state) => (id) => {
    return state.spaces.find((space: Space) => space.id === id);
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  addSpace(state: S, space: Space): void;
  updateSpace(state: S, payload: { spaceId: string; space: Space }): void;
  increaseHorse(state: S, spaceId: string): void;
  decreaseHorse(state: S, spaceId: string): void;
  increaseStable(state: S, spaceId: string): void;
  decreaseStable(state: S, spaceId: string): void;
  increaseUser(state: S, spaceId: string): void;
  decreaseUser(state: S, spaceId: string): void;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    // acquire initial state
    Object.assign(state, initialState());
  },
  addSpace(state, space) {
    state.spaces.push(space);
  },
  updateSpace(state, payload) {
    const index = state.spaces.findIndex(
      (space: Space) => space.id === payload.spaceId
    );
    if (index) {
      const newSpace = Object.assign(state.spaces[index], payload.space);
      Vue.set(state.spaces, index, newSpace);
    }
  },
  increaseHorse(state, spaceId) {
    const space = state.spaces.find((space: Space) => space.id === spaceId);
    if (space) {
      space.horseNumber++;
    }
  },
  decreaseHorse(state, spaceId) {
    const space = state.spaces.find((space: Space) => space.id === spaceId);
    if (space) {
      space.horseNumber--;
    }
  },
  increaseStable(state, spaceId) {
    const space = state.spaces.find((space: Space) => space.id === spaceId);
    if (space) {
      space.stableNumber++;
    }
  },
  decreaseStable(state, spaceId) {
    const space = state.spaces.find((space: Space) => space.id === spaceId);
    if (space) {
      space.stableNumber--;
    }
  },
  increaseUser(state, spaceId) {
    const space = state.spaces.find((space: Space) => space.id === spaceId);
    if (space) {
      space.userNumber++;
    }
  },
  decreaseUser(state, spaceId) {
    const space = state.spaces.find((space: Space) => space.id === spaceId);
    if (space) {
      space.userNumber--;
    }
  }
};

// actions
export type SpaceCreate = {
  space: Space;
  error?: { field: string; message: string };
};
export interface Actions {
  loadSpaces({ commit }: ActionContext<State, RootState>): Promise<Space[]>;
  loadSpaceData(
    { rootGetters, commit, dispatch }: ActionContext<State, RootState>,
    spaceId: string
  ): Promise<string | ErrorItem>;
  createSpace(
    { rootGetters, commit }: ActionContext<State, RootState>,
    payload: { space: Space; avatar: File; payment: Payment | null }
  ): Promise<SpaceCreate | ErrorItem>;
}

const actions: ActionTree<State, RootState> & Actions = {
  loadSpaces({ commit }) {
    return new Promise((resolve, reject) => {
      loadUserSpaces()
        .then((spaces) => {
          for (const space of spaces) {
            commit('addSpace', space);
            // checkSpace(commit, space);
          }
          resolve(spaces);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadSpaceData({ rootGetters, commit, dispatch }, spaceId) {
    return new Promise((resolve, reject) => {
      const space = rootGetters['SpaceModule/getById'](spaceId);
      if (
        space
        // &&
        // (space.status === SPACE_STATUS.ACTIVE ||
        //   space.status === SPACE_STATUS.PENDING_CANCELLATION)
      ) {
        commit('HorseModule/reset', {}, { root: true });
        commit('StableModule/reset', {}, { root: true });
        loadStables(spaceId)
          .then((stablesFaunaData) => {
            if (stablesFaunaData.length > 0) {
              Promise.all(
                stablesFaunaData.map((stableFaunaData) =>
                  loadStablesDetails(
                    rootGetters,
                    commit,
                    dispatch,
                    stableFaunaData
                  )
                )
              )
                .then(() => {
                  loadNotifications(spaceId);
                  dispatch('SystemEventModule/loadAll', spaceId, {
                    root: true
                  });
                  commit('UserModule/setCurrentSpace', space, { root: true });
                  resolve('ok');
                })
                .catch(() => {
                  reject('nok');
                });
            } else {
              commit('UserModule/setCurrentSpace', space, { root: true });
              resolve('ok');
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        if (space && space.status === SPACE_STATUS.INACTIVE) {
          // reject(ERROR_LIST.SPACE_INACTIVE);
        } else {
          reject(ERROR_LIST.SPACE_NO_ACCESS);
        }
      }
    });
  },
  createSpace({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post<SpaceCreate>(CREATE_SPACE_URL, {
          name: payload.space.name,
          code: secret,
          plan: payload.space.plan,
          ...(payload.payment ? { payment: payload.payment } : {})
        })
        .then((res) => {
          const space = Object.assign(payload.space, res.data.space);
          const error = res.data.error;
          if (space.plan.type === PLAN.FREE) {
            commit('UserModule/setFreePlan', null, { root: true });
          }
          if (payload.avatar) {
            upload(payload.avatar, {
              tags: 'space-avatar',
              folder: space.id
            })
              .then((result) => {
                space.avatar = result.url;
                editSpace(space)
                  .then(() => {
                    commit('addSpace', space);
                    resolve({ space: space, error: error ? error : undefined });
                  })
                  .catch((error) => {
                    Vue.prototype.$rollbar.error(error);
                    //TODO delete image in ImageKit, it requires to be executed from backend
                    commit('addSpace', space);
                    reject(ERROR_LIST.SPACE_CREATE_IMAGE_FAIL);
                  });
              })
              .catch(() => {
                commit('addSpace', space);
                reject(ERROR_LIST.SPACE_CREATE_IMAGE_FAIL);
              });
          } else {
            commit('addSpace', space);
            resolve({ space: space, error: error ? error : undefined });
          }
        })
        .catch((error: AxiosError<{ message: string; details?: string }>) => {
          if (error.response?.data.message === 'User denied') {
            const user = rootGetters['UserModule/getUser'];
            if (user.freePlan) {
              reject(ERROR_LIST.SPACE_CREATE_FAIL_LIMIT);
            }
          }
          reject(ERROR_LIST.SPACE_CREATE_FAIL);
        });
    });
  }
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const SpaceModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations
};
