import store from '@/store/index';
import router, { LOGIN } from '@/router/index';
import { ErrorItem } from '@/helpers/errors';
import { i18n } from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import { DataType } from '@/helpers/types';
import Vue from 'vue';

function logout(): void {
  store.commit('UserModule/reset');
  router.push({ name: LOGIN }).then(() => {
    store.dispatch('reset');
  });
}

function logoutError(error: ErrorItem): void {
  store.commit('UserModule/reset');
  router
    .push({
      name: LOGIN,
      query: { error: error.code.toString() }
    })
    .then(() => {
      store.dispatch('reset');
    });
}

function showError(error: ErrorItem): void {
  if (error) {
    router.replace({ query: { error: error.code.toString() } });
  } else {
    Vue.prototype.$rollbar.error('Error not defined:' + error);
  }
}

function translate(word: string, data: DataType = null): TranslateResult {
  if (data) {
    const newData: Record<string, string | TranslateResult> = {};
    for (const key in data) {
      const obj = data[key];
      if (obj.code) {
        newData[key] = i18n.t(obj.value);
      } else {
        newData[key] = obj.value;
      }
    }
    return i18n.t(word, newData);
  } else {
    return i18n.t(word);
  }
}

function shortStringDots(name: string, n: number): string {
  return name.length > n ? name.substr(0, n - 3) + '...' : name;
}

function translateArray(stringArr: string[]): string[] {
  const localized: string[] = [];
  if (stringArr) {
    stringArr.forEach((item: string) => {
      localized.push(translate(item).toString());
    });
  }
  return localized;
}

export {
  logout,
  logoutError,
  showError,
  translate,
  shortStringDots,
  translateArray
};
