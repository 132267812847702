import faunadb, { values } from "faunadb";
import {
  CalendarEventFauna,
  CreateCalendarEventResponseFauna,
  CreateEIAResponseFauna,
  CreateGlandersResponseFauna,
  CreateHealthReportResponseFauna,
  CreateHorseResponseFauna,
  CreateHorseShoeResponseFauna,
  CreateSportActivityResponseFauna,
  CreateVaccineResponseFauna,
  CreateVermifugeResponseFauna,
  ExamGlandersEIAFauna,
  FeedItemFauna,
  FoodFauna,
  HealthReportFauna,
  HorseFauna,
  HorsesFauna,
  HorseShoeFauna,
  LoginFauna,
  NotificationFauna,
  PlatformConfigurationFauna,
  SpaceFauna,
  SpacesFauna,
  SportActivityFauna,
  StableFauna,
  StableFaunaData,
  StablesFauna,
  SystemEventFauna,
  UpdateFoodRemoveSupplementFauna,
  UserFauna,
  UserSpace,
  UserToSpaceFauna,
  VaccineFauna,
  VermifugeFauna
} from "./fauna-types";
import {
  callFunction,
  deleteDocument,
  queryAllDocumentsByIndex,
  queryDocument,
  queryDocumentsByRef,
  replaceDocument,
  updateDocument
} from "@/helpers/data/fauna-helpers";
import { Space } from "@/store/modules/space";
import { User } from "@/store/modules/user";
import SecureLS from "secure-ls";
import { Horse } from "@/store/modules/horse";
import { Diet } from "@/store/modules/food";
import { FeedItem } from "@/store/modules/feed";
import { ERROR_LIST } from "@/helpers/errors";
import { Stable } from "@/store/modules/stable";
import { HorseShoe } from "@/store/modules/horseShoe";
import { SystemEvent } from "@/store/modules/systemevent";
import { Notification } from "@/helpers/notifications";
import {
  CalendarEventConvert,
  CalendarEventFaunaConvert,
  CalendarEventsConvert,
  eventConvert,
  eventsConvert,
  ExamGlandersEIAConvert,
  ExamGlandersEIAFaunaConvert,
  ExamGlandersEIAListConvert,
  feedConvert,
  feedsConvert,
  foodConvert,
  foodsConvert,
  healthReportConvert,
  healthReportFaunaConvert,
  healthReportsConvert,
  horseConvert,
  horseFaunaConvert,
  horseShoeConvert,
  horseShoeFaunaConvert,
  horseShoesConvert,
  notificationConvert,
  notificationsConvert,
  spacesConvert,
  SportActivitiesConvert,
  SportActivityConvert,
  SportActivityFaunaConvert,
  stableConvert,
  stableFaunaConvert,
  userFaunaConvert,
  vaccineConvert,
  vaccineFaunaConvert,
  vaccinesConvert,
  vermifugeConvert,
  vermifugeFaunaConvert,
  vermifugesConvert
} from "@/helpers/data/fauna-converter";
import { PublicPlatformConfiguration } from "@/helpers/platformConfiguration";
import { ExamGlandersEIA, HealthReport, Vaccine, Vermifuge } from "@/store/modules/health";
import Vue from "vue";
import { SportActivity } from "@/store/modules/sport";
import { CalendarEvent } from "@/store/modules/calendar";
import Ref = values.Ref;

const ls = new SecureLS({ isCompression: false });
const q = faunadb.query;

function secretReset(): string {
  return process.env.VUE_APP_FAUNADB_USERS_LOGIN_CREATE || '';
}

function secretBaseOrUser(): string {
  const faunaUserToken = ls.get('fauna');
  if (faunaUserToken) {
    return faunaUserToken;
  } else {
    return secretReset();
  }
}

export let secret: string = secretBaseOrUser();
export const secretLogin: string = secretReset();

const logout = async function (): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    client
      .query(q.Logout(false))
      .then((res) => {
        if (res) {
          secret = secretReset();
        }
        resolve(true);
      })
      .catch(() => {
        reject(null);
      });
  });
};

const login = async function (email: string, password: string): Promise<User> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secretLogin });
    callFunction<LoginFauna>(client, 'login', email, password)
      .then((res) => {
        if (res.user && res.secret) {
          secret = res.secret;
          ls.set('fauna', secret);
          resolve({
            id: res.user.ref.id,
            name: res.user.data.name,
            email: res.user.data.email,
            avatar: res.user.data.avatar,
            phone: res.user.data.phone,
            dob: res.user.data.dob,
            spaces: [],
            freePlan: res.user.data.freePlan
              ? res.user.data.freePlan.date.toISOString()
              : null
          });
        } else if (
          res.errorMessage &&
          res.errorMessage === 'user not confirmed'
        ) {
          reject(ERROR_LIST.LOGIN_NOT_CONFIRMED);
        }
      })
      .catch((error) => {
        if (error == ERROR_LIST.FAUNA_BAD_REQUEST_FUNCTION_ERROR) {
          const errorCause =
            error.errorStack?.requestResult?.responseContent?.errors[0]
              ?.cause[0]?.code;
          if (errorCause === 'authentication failed') {
            reject(ERROR_LIST.LOGIN_PASSWORD_INVALID);
          } else {
            reject(error);
          }
        } else {
          reject(error);
        }
      });
  });
};

const loadUserSpaces = async function (): Promise<Space[]> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    callFunction<SpacesFauna>(client, 'spacesByUser')
      .then((res) => {
        if (res) {
          let spaces: Space[] = [];
          if (res.spaces) {
            spaces = spacesConvert(res);
          }
          resolve(spaces);
        } else {
          reject(null);
        }
      })
      .catch(() => {
        reject(null);
      });
  });
};

const loadStables = async function (
  spaceId: string
): Promise<StableFaunaData[]> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<StablesFauna>(
      client,
      'stables_by_space',
      q.Ref(q.Collection('spaces'), spaceId)
    )
      .then((res) => {
        let stables: StableFaunaData[] = [];
        if (res) {
          stables = res.map((item: StableFauna) => {
            const stable = item.data;
            stable.id = item.ref.id;
            return stable;
          });
        }
        resolve(stables);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const loadHorses = async function (refs: Ref[]): Promise<Horse[]> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryDocumentsByRef<HorsesFauna>(client, refs)
      .then((res) => {
        if (res) {
          let horses: Horse[] = [];
          horses = res.map((item: HorseFauna) => {
            return horseConvert(item);
          });
          resolve(horses);
        } else {
          reject(null);
        }
      })
      .catch((e) => {
        Vue.prototype.$rollbar.error(e);
        reject(null);
      });
  });
};

/*
 * Be aware this function returns the actual food
 * index food_by_horse_dateEnd parameters:
 * @Horse Ref
 * @dateEnd_empty (true/false)
 */
const loadFoodActual = async function (horseId: string): Promise<Diet | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<FoodFauna[]>(client, 'food_by_horse_dateEnd', [
      q.Ref(q.Collection('horses'), horseId),
      true
    ])
      .then((res) => {
        if (res && res.length === 1) {
          resolve(foodConvert(res[0]));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

const createFoodForHorses = async function (
  horsesId: string[],
  diet: Diet
): Promise<{
  diets: Diet[];
  feeds: FeedItem[];
  systemEvents: SystemEvent[];
  notifications: Notification[];
}> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    callFunction<CreateHorseResponseFauna[]>(
      client,
      'createFoodForHorses',
      horsesId,
      diet
    )
      .then((res) => {
        if (res) {
          const notifications: Notification[] = [];
          const events: SystemEvent[] = [];
          const diets: Diet[] = [];
          const feeds: FeedItem[] = [];
          res.forEach((obj: CreateHorseResponseFauna) => {
            diets.push(foodConvert(obj.food));
            feeds.push(feedConvert(obj.feed));
            obj.eventAndNotification.forEach(
              (
                item:
                  | {
                      systemEvent: SystemEventFauna;
                      notification: NotificationFauna;
                    }
                  | string
              ) => {
                if (typeof item !== 'string') {
                  events.push(eventConvert(item.systemEvent));
                  notifications.push(notificationConvert(item.notification));
                }
              }
            );
          });
          resolve({
            diets: diets,
            feeds: feeds,
            systemEvents: events,
            notifications: notifications
          });
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/*
 * Be aware this function ignore the current version and delivery only the history.
 * index food_by_horse_dateEnd parameters:
 * @Horse Ref
 * @dateEnd_empty (true/false)
 */
const loadFoodHistory = async function (
  horseId: string
): Promise<Diet[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<FoodFauna[]>(client, 'food_by_horse_dateEnd', [
      q.Ref(q.Collection('horses'), horseId),
      false
    ])
      .then((res) => {
        if (res.length) {
          resolve(foodsConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const loadFeedByHorse = async function (
  horseId: string
): Promise<FeedItem[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<FeedItemFauna[]>(
      client,
      'feed_by_horse',
      q.Ref(q.Collection('horses'), horseId)
    )
      .then((res) => {
        if (res && res.length) {
          resolve(feedsConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

const dbCreateActivityCalendarEventProgram = async function (
  horseId: string,
  referenceId: string,
  calendarEvent: CalendarEvent
): Promise<{
  calendarEvent: CalendarEvent;
}> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaCalendarEvent = CalendarEventFaunaConvert(calendarEvent);
    callFunction<CreateCalendarEventResponseFauna>(
      client,
      'createActivityCalendarEventForHorse',
      horseId,
      referenceId,
      faunaCalendarEvent
    )
      .then((res) => {
        if (res) {
          const event: CalendarEvent = CalendarEventConvert(res.calendarEvent);
          resolve({
            calendarEvent: event
          });
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const dbLoadActivityCalendarEventFromHorse = async function (
  horseId: string
): Promise<CalendarEvent[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<CalendarEventFauna[]>(
      client,
      'calendarEvents_by_horse',
      [q.Ref(q.Collection('horses'), horseId)]
    )
      .then((res) => {
        if (res.length) {
          resolve(CalendarEventsConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

const dbLoadActivityCalendarEventFromSportActivity = async function (
  referenceId: string
): Promise<CalendarEvent[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<CalendarEventFauna[]>(
      client,
      'calendarEvents_by_sportActivity',
      [q.Ref(q.Collection('activities'), referenceId)]
    )
      .then((res) => {
        if (res.length) {
          resolve(CalendarEventsConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

const dbCreateSportActivityProgram = async function (
  horseId: string,
  activity: SportActivity
): Promise<{
  activity: SportActivity;
  feed: FeedItem;
  notification: Notification;
}> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaActivity = SportActivityFaunaConvert(activity);
    callFunction<CreateSportActivityResponseFauna>(
      client,
      'createActivityForHorse',
      horseId,
      faunaActivity
    )
      .then((res) => {
        if (res) {
          const sportActivity: SportActivity = SportActivityConvert(
            res.activity
          );
          const feed: FeedItem = feedConvert(res.feed);
          const notification: Notification = notificationConvert(
            res.notification
          );
          resolve({
            activity: sportActivity,
            feed: feed,
            notification: notification
          });
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const dbLoadSportActivityFromHorse = async function (
  horseId: string
): Promise<SportActivity[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<SportActivityFauna[]>(
      client,
      'activities_by_horse',
      [q.Ref(q.Collection('horses'), horseId)]
    )
      .then((res) => {
        if (res.length) {
          resolve(SportActivitiesConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

const editSpace = async function (space: Space): Promise<Space> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    updateDocument<SpaceFauna>(client, 'spaces', space.id, {
      name: space.name,
      avatar: space.avatar
    })
      .then(() => {
        resolve(space);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const dbEditUser = async function (user: User): Promise<User> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const userFauna = userFaunaConvert(user);
    updateDocument<UserFauna>(client, 'users', user.id, {
      ...userFauna
    })
      .then(() => {
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const dbCreateHorse = async function (
  horse: Horse,
  stableId: string,
  spaceId: string
): Promise<Horse> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const horseFauna = horseFaunaConvert(horse);
    callFunction<HorseFauna>(
      client,
      'createHorse',
      { ...horseFauna, space: q.Ref(q.Collection('spaces'), spaceId) },
      q.Ref(q.Collection('stables'), stableId)
    )
      .then((res) => {
        if (res) {
          resolve(horseConvert(res));
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const dbEditHorse = async function (horse: Horse): Promise<Horse> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const horseFauna = horseFaunaConvert(horse);
    updateDocument<HorseFauna>(client, 'horses', horse.id, {
      ...horseFauna
    })
      .then(() => {
        resolve(horse);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const dbEditHorseShoe = async function (
  horseShoe: HorseShoe
): Promise<HorseShoe> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaHorseShoe = horseShoeFaunaConvert(horseShoe);
    updateDocument<HorseShoeFauna>(client, 'horseshoes', horseShoe.id, {
      ...faunaHorseShoe
    })
      .then(() => {
        resolve(horseShoe);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const dbPatchSportActivity = async function (
  sportActivity: SportActivity
): Promise<SportActivity> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaSportActivity = SportActivityFaunaConvert(sportActivity);
    updateDocument<SportActivityFauna>(client, 'activities', sportActivity.id, {
      ...faunaSportActivity
    })
      .then(() => {
        resolve(sportActivity);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const dbPutSportActivity = async function (
  sportActivity: SportActivity
): Promise<SportActivity> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaSportActivity = SportActivityFaunaConvert(sportActivity);
    replaceDocument<SportActivityFauna>(
      client,
      'activities',
      sportActivity.id,
      {
        ...faunaSportActivity,
        horseRef: q.Ref(q.Collection('horses'), sportActivity.horseId)
      }
    )
      .then(() => {
        resolve(sportActivity);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const dbEditCalendarEvent = async function (
  calendarEvent: CalendarEvent
): Promise<CalendarEvent> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaCalendarEvent = CalendarEventFaunaConvert(calendarEvent);
    updateDocument<CalendarEventFauna>(
      client,
      'calendarEvents',
      calendarEvent.id,
      {
        ...faunaCalendarEvent
      }
    )
      .then(() => {
        resolve(calendarEvent);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const dbEditHealthReport = async function (
  healthReport: HealthReport
): Promise<HealthReport> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaHealthReport = healthReportFaunaConvert(healthReport);
    updateDocument<HealthReportFauna>(
      client,
      'healthReports',
      healthReport.id,
      {
        ...faunaHealthReport
      }
    )
      .then(() => {
        resolve(healthReport);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createStable = async function (
  stable: Stable,
  spaceId: string
): Promise<Stable> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    callFunction<StableFauna>(client, 'createStable', {
      name: stable.name,
      professionals: stable.professionals,
      address: stable.address,
      phone: stable.phone,
      space: q.Ref(q.Collection('spaces'), spaceId),
      horsesRef: []
    })
      .then((item) => {
        resolve(stableConvert(item));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const editStable = async function (stable: Stable): Promise<Stable> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const stableFauna = stableFaunaConvert(stable);
    updateDocument<SpaceFauna>(client, 'stables', stable.id, {
      ...stableFauna
    })
      .then(() => {
        resolve(stable);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createHorseShoesForHorses = async function (
  horsesId: string[],
  horseShoe: HorseShoe
): Promise<{
  horseShoes: HorseShoe[];
  feeds: FeedItem[];
  notifications: Notification[];
}> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaHorseShoe = horseShoeFaunaConvert(horseShoe);
    callFunction<CreateHorseShoeResponseFauna[]>(
      client,
      'createHorseShoesForHorses',
      horsesId,
      faunaHorseShoe
    )
      .then((res) => {
        if (res) {
          const notifications: Notification[] = [];
          const horseShoes: HorseShoe[] = [];
          const feeds: FeedItem[] = [];
          res.forEach((obj: CreateHorseShoeResponseFauna) => {
            horseShoes.push(horseShoeConvert(obj.horseshoe));
            feeds.push(feedConvert(obj.feed));
            notifications.push(notificationConvert(obj.notification));
          });
          resolve({
            horseShoes: horseShoes,
            feeds: feeds,
            notifications: notifications
          });
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createHealthReportForHorse = async function (
  horseId: string,
  healthReport: HealthReport
): Promise<{
  healthReport: HealthReport;
  feed: FeedItem;
}> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaHealthReport = healthReportFaunaConvert(healthReport);
    callFunction<CreateHealthReportResponseFauna>(
      client,
      'createHealthReportsForHorses',
      horseId,
      faunaHealthReport
    )
      .then((res) => {
        if (res) {
          const hr: HealthReport = healthReportConvert(res.healthReport);
          const feed: FeedItem = feedConvert(res.feed);
          resolve({
            healthReport: hr,
            feed: feed
          });
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createVermifugeForHorse = async function (
  horseId: string,
  vermifuge: Vermifuge
): Promise<{
  vermifuge: Vermifuge;
  feed: FeedItem;
  notification: Notification;
}> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaVermifuge = vermifugeFaunaConvert(vermifuge);
    callFunction<CreateVermifugeResponseFauna>(
      client,
      'createVermifugeForHorse',
      horseId,
      faunaVermifuge
    )
      .then((res) => {
        if (res) {
          const vermifuge: Vermifuge = vermifugeConvert(res.vermifuge);
          const feed: FeedItem = feedConvert(res.feed);
          const notification: Notification = notificationConvert(
            res.notification
          );
          resolve({
            vermifuge: vermifuge,
            feed: feed,
            notification: notification
          });
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createVaccineForHorse = async function (
  horseId: string,
  vaccine: Vaccine
): Promise<{
  vaccine: Vaccine;
  feed: FeedItem;
  notification: Notification;
}> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaVaccine = vaccineFaunaConvert(vaccine);
    callFunction<CreateVaccineResponseFauna>(
      client,
      'createVaccineForHorse',
      horseId,
      faunaVaccine
    )
      .then((res) => {
        if (res) {
          const vaccine: Vaccine = vaccineConvert(res.vaccine);
          const feed: FeedItem = feedConvert(res.feed);
          const notification: Notification = notificationConvert(
            res.notification
          );
          resolve({
            vaccine: vaccine,
            feed: feed,
            notification: notification
          });
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createEIAForHorse = async function (
  horseId: string,
  eia: ExamGlandersEIA
): Promise<{
  eia: ExamGlandersEIA;
  feed: FeedItem;
  notification: Notification;
}> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaEIA = ExamGlandersEIAFaunaConvert(eia);
    callFunction<CreateEIAResponseFauna>(
      client,
      'createEIAForHorse',
      horseId,
      faunaEIA
    )
      .then((res) => {
        if (res) {
          const eia: ExamGlandersEIA = ExamGlandersEIAConvert(res.eia);
          const feed: FeedItem = feedConvert(res.feed);
          const notification: Notification = notificationConvert(
            res.notification
          );
          resolve({
            eia: eia,
            feed: feed,
            notification: notification
          });
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const createGlandersForHorse = async function (
  horseId: string,
  glanders: ExamGlandersEIA
): Promise<{
  glanders: ExamGlandersEIA;
  feed: FeedItem;
  notification: Notification;
}> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    const faunaGlanders = ExamGlandersEIAFaunaConvert(glanders);
    callFunction<CreateGlandersResponseFauna>(
      client,
      'createGlandersForHorse',
      horseId,
      faunaGlanders
    )
      .then((res) => {
        if (res) {
          const glanders: ExamGlandersEIA = ExamGlandersEIAConvert(
            res.glanders
          );
          const feed: FeedItem = feedConvert(res.feed);
          const notification: Notification = notificationConvert(
            res.notification
          );
          resolve({
            glanders: glanders,
            feed: feed,
            notification: notification
          });
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/*
 * Be aware this function returns the actual horseshoe
 * index horse_shoe_by_horse_dateEnd parameters:
 * @Horse Ref
 * @dateEnd_empty (true/false)
 */
const loadHorseShoeActual = async function (
  horseId: string
): Promise<HorseShoe | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<HorseShoeFauna[]>(
      client,
      'horseshoes_by_horse_dateEnd',
      [q.Ref(q.Collection('horses'), horseId), true]
    )
      .then((res) => {
        if (res && res.length === 1) {
          resolve(horseShoeConvert(res[0]));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

/*
 * Be aware this function ignore the current version and delivery only the history.
 * index horse_shoe_by_horse_dateEnd parameters:
 * @Horse Ref
 * @dateEnd_empty (true/false)
 */
const loadHorseShoeHistory = async function (
  horseId: string
): Promise<HorseShoe[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<HorseShoeFauna[]>(
      client,
      'horseshoes_by_horse_dateEnd',
      [q.Ref(q.Collection('horses'), horseId), false]
    )
      .then((res) => {
        if (res.length) {
          resolve(horseShoesConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const loadHealthReports = async function (
  horseId: string
): Promise<HealthReport[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<HealthReportFauna[]>(
      client,
      'healthReports_by_horse',
      [q.Ref(q.Collection('horses'), horseId)]
    )
      .then((res) => {
        if (res.length) {
          resolve(healthReportsConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

const loadVermifuges = async function (
  horseId: string
): Promise<Vermifuge[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<VermifugeFauna[]>(client, 'vermifuges_by_horse', [
      q.Ref(q.Collection('horses'), horseId)
    ])
      .then((res) => {
        if (res.length) {
          resolve(vermifugesConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

const loadVaccines = async function (
  horseId: string
): Promise<Vaccine[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<VaccineFauna[]>(client, 'vaccines_by_horse', [
      q.Ref(q.Collection('horses'), horseId)
    ])
      .then((res) => {
        if (res.length) {
          resolve(vaccinesConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

const loadEIA = async function (
  horseId: string
): Promise<ExamGlandersEIA[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<ExamGlandersEIAFauna[]>(client, 'eia_by_horse', [
      q.Ref(q.Collection('horses'), horseId)
    ])
      .then((res) => {
        if (res.length) {
          resolve(ExamGlandersEIAListConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

const loadGlanders = async function (
  horseId: string
): Promise<ExamGlandersEIA[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<ExamGlandersEIAFauna[]>(
      client,
      'glanders_by_horse',
      [q.Ref(q.Collection('horses'), horseId)]
    )
      .then((res) => {
        if (res.length) {
          resolve(ExamGlandersEIAListConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

/*
 * Be aware this function returns the actual health report
 * index healthReports_by_horse_dateEnd parameters:
 * @Horse Ref
 * @dateEnd_empty (true/false)
 */
const loadHealthReportActual = async function (
  horseId: string
): Promise<HealthReport[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<HealthReportFauna[]>(
      client,
      'healthReports_by_horse_dateEnd',
      [q.Ref(q.Collection('horses'), horseId), true]
    )
      .then((res) => {
        if (res.length) {
          resolve(healthReportsConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

/*
 * Be aware this function ignore the current version and delivery only the history.
 * index horse_shoe_by_horse_dateEnd parameters:
 * @Horse Ref
 * @dateEnd_empty (true/false)
 */
const loadHealthReportHistory = async function (
  horseId: string
): Promise<HealthReport[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<HealthReportFauna[]>(
      client,
      'healthReports_by_horse_dateEnd',
      [q.Ref(q.Collection('horses'), horseId), false]
    )
      .then((res) => {
        if (res.length) {
          resolve(healthReportsConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const loadSystemEventBySpace = async function (
  spaceId: string
): Promise<SystemEvent[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<SystemEventFauna[]>(
      client,
      'systemEvent_by_space',
      q.Ref(q.Collection('spaces'), spaceId)
    )
      .then((res) => {
        if (res && res.length) {
          resolve(eventsConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

const loadNotificationBySpace = async function (
  spaceId: string
): Promise<Notification[] | null> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    queryAllDocumentsByIndex<NotificationFauna[]>(
      client,
      'notification_by_space',
      q.Ref(q.Collection('spaces'), spaceId)
    )
      .then((res) => {
        if (res && res.length) {
          resolve(notificationsConvert(res));
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.name === 'NotFound') {
          resolve(null);
        }
        reject(error);
      });
  });
};

const markAsReadNotification = async function (id: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    updateDocument<NotificationFauna>(client, 'notifications', id, {
      read: true
    })
      .then(() => {
        resolve('OK');
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteNotification = async function (id: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    deleteDocument<NotificationFauna>(client, 'notifications', id)
      .then(() => {
        resolve('OK');
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteFromFaunaDbCollection = async function (
  id: string,
  collection: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    deleteDocument<NotificationFauna>(client, collection, id)
      .then(() => {
        resolve('OK');
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateFoodRemoveSupplement = async function (
  foodId: string,
  foodItemId: string,
  horseId: string,
  notificationId: string,
  systemEventId: string
): Promise<{ diet: Diet; feed: FeedItem }> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    callFunction<UpdateFoodRemoveSupplementFauna>(
      client,
      'updateFoodRemoveSupplement',
      foodId,
      foodItemId,
      horseId,
      notificationId,
      systemEventId
    )
      .then((res) => {
        if (res) {
          resolve({
            diet: foodConvert(res.food),
            feed: feedConvert(res.feed)
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const loadPublicPlatformConfig =
  async function (): Promise<PublicPlatformConfiguration> {
    return new Promise((resolve, reject) => {
      const client = new faunadb.Client({ secret: secret });
      queryDocument<PlatformConfigurationFauna>(
        client,
        'platformConfiguration',
        '160165142154151143'
      )
        .then((res) => {
          if (res) {
            resolve(res.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

const usersBySpace = async function (spaceId: string): Promise<UserSpace[]> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    callFunction<UserSpace[]>(client, 'usersBySpace', spaceId)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const addUserToSpace = async function (
  email: string,
  role: string,
  spaceId: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    callFunction<UserToSpaceFauna>(
      client,
      'addUserToSpace',
      email,
      role,
      spaceId
    )
      .then(() => {
        resolve('OK');
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateUserRole = async function (
  roleId: string,
  role: string,
  spaceId: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    callFunction<UserToSpaceFauna>(
      client,
      'updateUserRole',
      roleId,
      role,
      spaceId
    )
      .then(() => {
        resolve('OK');
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const removeUserFromSpace = async function (
  roleId: string,
  spaceId: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const client = new faunadb.Client({ secret: secret });
    callFunction<UserToSpaceFauna>(
      client,
      'removeUserFromSpace',
      roleId,
      spaceId
    )
      .then(() => {
        resolve('OK');
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  login,
  logout,
  loadUserSpaces,
  editSpace,
  dbEditUser,
  dbEditHorseShoe,
  dbCreateHorse,
  dbEditHorse,
  dbEditHealthReport,
  loadStables,
  createStable,
  editStable,
  loadHorses,
  loadFoodActual,
  createFoodForHorses,
  createHorseShoesForHorses,
  createHealthReportForHorse,
  createVermifugeForHorse,
  createVaccineForHorse,
  createEIAForHorse,
  createGlandersForHorse,
  loadHorseShoeActual,
  loadHorseShoeHistory,
  loadHealthReportHistory,
  loadHealthReportActual,
  loadHealthReports,
  loadVermifuges,
  loadVaccines,
  loadEIA,
  loadGlanders,
  loadFoodHistory,
  loadFeedByHorse,
  loadSystemEventBySpace,
  loadNotificationBySpace,
  markAsReadNotification,
  deleteNotification,
  updateFoodRemoveSupplement,
  loadPublicPlatformConfig,
  usersBySpace,
  addUserToSpace,
  updateUserRole,
  removeUserFromSpace,
  deleteFromFaunaDbCollection,
  dbCreateActivityCalendarEventProgram,
  dbCreateSportActivityProgram,
  dbPatchSportActivity,
  dbPutSportActivity,
  dbEditCalendarEvent,
  dbLoadActivityCalendarEventFromHorse,
  dbLoadActivityCalendarEventFromSportActivity,
  dbLoadSportActivityFromHorse
};
