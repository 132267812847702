
import { mapGetters } from 'vuex';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

interface Data {
  show: boolean;
  msg: TranslateResult;
  multiline: boolean;
  type: string;
}
interface Computed {
  lastNot: Notification | null;
}

export default Vue.extend<
  Data,
  Record<string, never>,
  Computed,
  Record<string, never>
>({
  name: 'Notifications',
  data: () => ({
    show: false,
    msg: '',
    multiline: true,
    type: 'primary'
  }),
  computed: {
    ...mapGetters({
      lastNot: 'NotificationModule/getLastNotification'
    })
  },
  watch: {
    lastNot(newValue) {
      if (newValue != null) {
        this.msg = newValue.msg;
        this.type = newValue.type;
        this.show = true;
        this.$store.commit('NotificationModule/markAsDisplayed', newValue.id);
      }
    }
  }
});
