import {
  mdiBed,
  mdiCarrot,
  mdiCheckboxBlankOutline,
  mdiHorseshoe,
  mdiHospitalBox,
  mdiReproduction,
  mdiTrophyVariant
} from '@mdi/js';

export interface ModulesTypes {
  HEALTH: ModuleItem;
  SPORT: ModuleItem;
  FOOD: ModuleItem;
  BED: ModuleItem;
  HORSESHOE: ModuleItem;
  REPRODUCTION: ModuleItem;
}

export interface ModuleItem {
  name: string;
  i18nCode: string;
  icon: string;
  color: string;
}

export const MODULE_TYPES: ModulesTypes = Object.freeze({
  HEALTH: {
    name: 'HEALTH',
    i18nCode: 'moduleTypes.health',
    icon: mdiHospitalBox,
    color: '#4CAF50'
  },
  FOOD: {
    name: 'FOOD',
    i18nCode: 'moduleTypes.food',
    icon: mdiCarrot,
    color: '#FF9800'
  },
  HORSESHOE: {
    name: 'HORSESHOE',
    i18nCode: 'moduleTypes.horseshoe',
    icon: mdiHorseshoe,
    color: '#9E9E9E'
  },
  BED: {
    name: 'BED',
    i18nCode: 'moduleTypes.bed',
    icon: mdiBed,
    color: '#9C27B0'
  },
  SPORT: {
    name: 'SPORT',
    i18nCode: 'moduleTypes.sport',
    icon: mdiTrophyVariant,
    color: '#FBC02D'
  },
  REPRODUCTION: {
    name: 'REPRODUCTION',
    i18nCode: 'moduleTypes.reproduction',
    icon: mdiReproduction,
    color: '#1976D2'
  },
  STABLE: {
    name: 'STABLE',
    i18nCode: 'moduleTypes.stable',
    icon: mdiCheckboxBlankOutline,
    color: '#8D6E63'
  }
});

export function getModuleType(type: string): ModuleItem {
  for (const item in MODULE_TYPES) {
    const module = MODULE_TYPES[item as keyof ModulesTypes];
    if (module.name === type) {
      return module;
    }
  }
  throw new Error(`ModuleType ${type} does not exist!`);
}

export const API_URL = process.env.VUE_APP_API_URL;
export const HELP_CENTER_URL = process.env.VUE_APP_HELP_CENTER_URL;

export const IMAGE_KIT_OPTIONS = {
  urlEndpoint: process.env.VUE_APP_IMAGEKIT_URL_ENDPOINT || '',
  publicKey: process.env.VUE_APP_IMAGEKIT_PUBLIC_KEY || '',
  authenticationEndpoint: API_URL + 'imagekit'
};

export const GALAXPAY_ID = process.env.VUE_APP_GALAXPAY_ID || '';

export const REGISTER_URL = API_URL + 'register/';
export const INVITE_URL = API_URL + 'invite-user/';
export const CONFIRM_EMAIL_URL = API_URL + 'confirm-email/';
export const RESEND_EMAIL_CONFIRMATION_CODE_URL =
  API_URL + 'resend-confirmation-code/';
export const RESEND_EMAIL_CONFIRMATION_EMAIL_URL =
  API_URL + 'resend-confirmation-email/';
export const RESET_PASS_URL = API_URL + 'reset-password/';
export const FORGOT_PASS_URL = API_URL + 'forgot-password/';
export const CARD_BRAND_URL = API_URL + 'card-brand/';
export const PAYMENT_URL = API_URL + 'payment/';
export const SPACE_URL = API_URL + 'space/';
export const SPACE_CANCEL_URL = SPACE_URL + 'cancel/';
export const CREATE_SPACE_URL = SPACE_URL;
export const CHECK_SPACE_URL = SPACE_URL + 'status/';

export const CALENDAR_VIEW_TYPES = Object.freeze({
  DAY: 'day',
  DAY4: '4day',
  WEEK: 'week',
  CUSTOM_WEEK: 'week',
  // CUSTOM_WEEK: 'custom-weekly',
  MONTH: 'month'
});

export const horseRaces = Object.freeze([
  'Álter',
  'Árabe (PSA)',
  'Akhal-Teke',
  'American Saddlebred',
  'American Trotter Ou Standardbred',
  'Andaluz',
  'Anglo-Árabe (AA)',
  'Appaloosa',
  'Asturcón',
  'Avelin',
  'Azteca',
  'Bardigiano',
  'Bashkir Curly',
  'Bolonhês',
  'Brabant',
  'Brasileiro de Hipismo (BH)',
  'Bretão',
  'Brumby',
  'Bérbere',
  'Cabardinos',
  'Camargue',
  'Campeiro',
  'Campolina',
  'Chilote',
  'Cleveland Bay',
  'Clydesdale',
  'Crioulo',
  'Danish Warmblood',
  'Falabella',
  'Finlandês',
  'Fjord',
  'Frederiksborg',
  'Friesian',
  'Galiceno',
  'Garrana',
  'Gotland',
  'Gypsy Vanner',
  'Hackney',
  'Haflinger',
  'Hanoveriano (HAN)',
  'Holsteiner (HOL)',
  'Irish Hunter',
  'Lavradeiro',
  'Lipizzaner',
  'Mangalarga',
  'Mangalarga Marchador (MM)',
  'Marajoara',
  'Marwari',
  'Mongol / Przewalski',
  'Morgan',
  'Mustang',
  'Nordestina',
  'Oldenburg (OLD)',
  'Orlov Trotter',
  'Paint Horse',
  'Palomino',
  'Pantaneiro',
  'Paso Peruano',
  'Percheron',
  'Piquira',
  'Pônei',
  'Puro Sangue Lusitano (PSL)',
  'Puro-Sangue Inglês (PSI)',
  'Puruca',
  'Quarab',
  'Quarto De Milha (QM)',
  'Sela Belga (SB)',
  'Sela Francesa (SF)',
  'Sela Holandesa (KWPN)',
  'Sem Raça Definida (SRD)',
  'Shetland',
  'Shire',
  'Sorraia',
  'Tarpan',
  'Tinker',
  'Trakehner',
  'Ucraniano',
  'Waler Australiano',
  'Welsh Pony',
  'Westfalen (WES)'
]);

//System Events Constants

export const FOOD_UPDATE_FOOD_ITEM = 'UPDATE.FOOD';
