import ImageKit from 'imagekit-javascript';
import { IMAGE_KIT_OPTIONS } from '@/store/constants';

function getClient(options: {
  urlEndpoint?: string;
  publicKey?: string;
  authenticationEndpoint?: string;
}): ImageKit {
  return new ImageKit({
    publicKey: options.publicKey || IMAGE_KIT_OPTIONS.publicKey,
    urlEndpoint: options.urlEndpoint || IMAGE_KIT_OPTIONS.urlEndpoint,
    authenticationEndpoint:
      options.authenticationEndpoint || IMAGE_KIT_OPTIONS.authenticationEndpoint
  });
}

function upload(
  file: File,
  options: {
    urlEndpoint?: string;
    publicKey?: string;
    authenticationEndpoint?: string;
    customCoordinates?: string;
    fileName?: string;
    folder?: string;
    useUniqueFileName?: boolean;
    isPrivateFile?: boolean;
    responseFields?: string;
    tags?: string;
  }
): Promise<ImageKit.UploadResponse> {
  return new Promise((resolve, reject) => {
    const IkClient = getClient(options);
    const publicKey = options.publicKey || IMAGE_KIT_OPTIONS.publicKey;
    const authenticationEndpoint =
      options.authenticationEndpoint ||
      IMAGE_KIT_OPTIONS.authenticationEndpoint;
    IkClient.upload(
      {
        file: file,
        fileName: options.fileName || file.name,
        useUniqueFileName: options.useUniqueFileName || true,
        tags: options.tags || '',
        folder: options.folder || '',
        isPrivateFile: options.isPrivateFile || false,
        customCoordinates: options.customCoordinates || '',
        responseFields: options.responseFields || ''
      },
      (err, response) => {
        if (err) {
          return reject(err);
        } else if (!err && response) {
          resolve(response);
        } else {
          reject('FATAL ERROR: Imagekit Lib Error');
        }
      },
      {
        publicKey,
        authenticationEndpoint
      }
    );
  });
}

export { upload };
