import { render, staticRenderFns } from "./AvatarImageKit.vue?vue&type=template&id=c8eb9744&scoped=true&%40click=forward()"
import script from "./AvatarImageKit.vue?vue&type=script&lang=ts"
export * from "./AvatarImageKit.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8eb9744",
  null
  
)

export default component.exports