
import Vue from 'vue';

interface Props {
  tooltipText: string;
  tooltipPosition: string;
  icon: string;
  iconSize: string;
}

interface Computed {
  tooltipPositionAttr: { [key: string]: string | boolean };
  iconSizeAttr: { [key: string]: string | boolean };
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'BtnIconTooltip',
  props: {
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: String,
      default: null
    },
    tooltipText: {
      type: String,
      default: null
    },
    tooltipPosition: {
      type: String,
      default: null
    }
  },
  computed: {
    tooltipPositionAttr() {
      if (this.tooltipPosition) {
        const objAttr: { [key: string]: string | boolean } = {};
        objAttr[this.tooltipPosition] = true;
        return objAttr;
      } else return {};
    },
    iconSizeAttr() {
      if (this.iconSize) {
        const objAttr: { [key: string]: string | boolean } = {};
        objAttr[this.iconSize] = true;
        return objAttr;
      } else return {};
    }
  }
});
