import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import { HorseModule, State as HorseState } from '@/store/modules/horse';
import { StableModule, State as StableState } from '@/store/modules/stable';
import { UserModule, State as UserState } from '@/store/modules/user';
import { SpaceModule, State as SpaceState } from '@/store/modules/space';
import { FoodModule, State as FoodState } from '@/store/modules/food';
import { HealthModule, State as HealthState } from '@/store/modules/health';
import { SportModule, State as SportState } from '@/store/modules/sport';
import {
  CalendarModule,
  State as CalendarState
} from '@/store/modules/calendar';
import {
  HorseShoeModule,
  State as HorseShoeState
} from '@/store/modules/horseShoe';
import { FeedModule, State as FeedState } from '@/store/modules/feed';
import {
  FoodSponsoredModule,
  State as FoodSponsoredState
} from '@/store/modules/sponsoredFood';
import {
  SystemEventModule,
  State as SystemEventState
} from '@/store/modules/systemevent';
import {
  NotificationModule,
  State as NotificationState
} from '@/store/modules/notification';
import { logout } from '@/helpers/data/fauna-queries';

//set encryption on local storage
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export type State = {
  stable: StableState;
  horse: HorseState;
  horseShoe: HorseShoeState;
  user: UserState;
  space: SpaceState;
  food: FoodState;
  health: HealthState;
  sport: SportState;
  calendar: CalendarState;
  foodSponsored: FoodSponsoredState;
  feed: FeedState;
  systemEvent: SystemEventState;
  notification: NotificationState;
};

export default new Vuex.Store({
  modules: {
    HorseModule,
    HorseShoeModule,
    StableModule,
    UserModule,
    SpaceModule,
    FoodModule,
    HealthModule,
    SportModule,
    CalendarModule,
    FoodSponsoredModule,
    FeedModule,
    SystemEventModule,
    NotificationModule
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ],
  actions: {
    reset({ commit }) {
      return logout()
        .then(() => {
          window.localStorage.clear();
        })
        .catch((error) => {
          //TODO what to do in this case? Fauna is out.. in any case we need to clean the data
          // should we report to a dashboard Fauna is out?
          Vue.prototype.$rollbar.error(error);
        })
        .finally(() => {
          commit('HorseModule/reset');
          commit('HorseShoeModule/reset');
          commit('StableModule/reset');
          commit('UserModule/reset');
          commit('SpaceModule/reset');
          commit('FoodModule/reset');
          commit('HealthModule/reset');
          commit('FeedModule/reset');
          commit('SystemEventModule/reset');
          commit('NotificationModule/reset');
          commit('CalendarModule/reset');
          commit('SportModule/reset');
          window.localStorage.clear();
        });
    }
  }
});
