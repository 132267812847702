import {
  Store as VuexStore,
  GetterTree,
  MutationTree,
  CommitOptions,
  DispatchOptions,
  Module,
  ActionContext,
  ActionTree
} from 'vuex';

import { State as RootState } from '@/store';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import { ModuleItem } from '@/store/constants';
import { Horse } from '@/store/modules/horse';
import { loadFeedByHorse } from '@/helpers/data/fauna-queries';
import { DataType } from '@/helpers/types';

export interface FeedItem {
  title: TranslateResult;
  titleData: DataType;
  subTitle: TranslateResult;
  subTitleData: DataType;
  userName: string;
  type: ModuleItem;
  horse: Horse | null;
  timestamp: Date;
}

export interface State {
  feed: Record<string, FeedItem[]>;
}

function initialState() {
  return {
    feed: {}
  };
}

// getters
export type Getters = {
  getByHorseId(state: State): (horseId: string) => FeedItem[] | null;
};

const getters: GetterTree<State, RootState> & Getters = {
  getByHorseId: (state) => (horseId) => {
    let diets = null;
    if (horseId in state.feed) diets = state.feed[horseId];
    return diets;
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  addHorseFeed(state: S, payload: { horseId: string; feeds: FeedItem[] }): void;
  addFeedInHorse(state: S, payload: { horseId: string; feed: FeedItem }): void;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    Object.assign(state, initialState());
  },
  addHorseFeed(state, payload) {
    if (payload.horseId) {
      Vue.set(state.feed, payload.horseId, payload.feeds);
    } else throw 'HorseId Cannot be null';
  },
  addFeedInHorse(state, payload) {
    if (payload.horseId) {
      if (payload.horseId in state.feed) {
        state.feed[payload.horseId].push(payload.feed);
      } else {
        Vue.set(state.feed, payload.horseId, [payload.feed]);
      }
    } else throw 'HorseId Cannot be null';
  }
};

// actions
export interface Actions {
  addFeedInHorse(
    { getters, commit }: ActionContext<State, RootState>,
    payload: { horseId: string; feed: FeedItem }
  ): Promise<void>;

  loadHorseFeed(
    { getters, commit }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<FeedItem[] | null>;

  loadStableFeed(
    { rootGetters, dispatch }: ActionContext<State, RootState>,
    stableId: string
  ): Promise<FeedItem[] | null>;
}

const actions: ActionTree<State, RootState> & Actions = {
  addFeedInHorse({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('loadHorseFeed', payload.horseId)
        .then(() => {
          commit('addFeedInHorse', payload);
          resolve();
        })
        .catch((error) => {
          Vue.prototype.$rollbar.error(error);
          reject();
        });
    });
  },
  loadHorseFeed({ getters, commit }, horseId) {
    return new Promise((resolve, reject) => {
      const feeds = getters['getByHorseId'](horseId);
      if (!feeds) {
        loadFeedByHorse(horseId)
          .then((horseFeed) => {
            if (horseFeed) {
              commit('addHorseFeed', { horseId: horseId, feeds: horseFeed });
            }
            resolve(horseFeed);
          })
          .catch((error) => {
            Vue.prototype.$rollbar.error(error);
            reject();
          });
      } else {
        resolve(feeds);
      }
    });
  },
  loadStableFeed({ rootGetters, dispatch }, stableId) {
    return new Promise((resolve) => {
      const horseIds = rootGetters['StableModule/getHorseIdsById'](stableId);
      if (horseIds) {
        const feed: FeedItem[] = [];
        Promise.all(
          horseIds.map((horseId: string) => {
            return dispatch('loadHorseFeed', horseId);
          })
        )
          .then((feedPerHorse) => {
            feedPerHorse.forEach((value) => {
              if (value) feed.push(...(value as FeedItem[]));
            });
            if (feed.length > 0) {
              resolve(feed);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            Vue.prototype.$rollbar.error(error);
          });
      } else {
        resolve(null);
      }
    });
  }
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const FeedModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
