import {
  Store as VuexStore,
  GetterTree,
  MutationTree,
  CommitOptions,
  Module
} from 'vuex';
import { State as RootState } from '@/store';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

export enum TYPE {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}

export type Notification = {
  id: number;
  msg: TranslateResult;
  type: typeof TYPE;
  displayed: boolean;
  createdAt: string;
};

//state
export interface State {
  notifications: Notification[];
}

function initialState() {
  return {
    notifications: []
  };
}

// getters
export type Getters = {
  getNotifications(state: State): Notification[];
  getLastNotification(state: State): Notification | null;
};

const getters: GetterTree<State, RootState> & Getters = {
  getNotifications: (state) => {
    return state.notifications;
  },
  getLastNotification: (state) => {
    return state.notifications.length > 0
      ? state.notifications[state.notifications.length - 1]
      : null;
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  add(state: S, payload: { msg: TranslateResult; type: typeof TYPE }): void;
  markAsDisplayed(state: S, id: number): void;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    // acquire initial state
    Object.assign(state, initialState());
  },
  add(state, payload) {
    const notification: Notification = {
      id: state.notifications.length,
      displayed: false,
      msg: payload.msg,
      type: payload.type,
      createdAt: new Date().toISOString()
    };
    state.notifications.push(notification);
  },
  markAsDisplayed(state, id) {
    state.notifications[id].displayed = true;
  }
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

export const NotificationModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations
};
