import {
  Store as VuexStore,
  GetterTree,
  MutationTree,
  CommitOptions,
  DispatchOptions,
  Module,
  ActionContext,
  ActionTree
} from 'vuex';

import { State as RootState } from '@/store';
import {
  createHorseShoesForHorses,
  dbEditHorseShoe,
  loadHorseShoeActual,
  loadHorseShoeHistory
} from '@/helpers/data/fauna-queries';
import Vue from 'vue';
import { ERROR_LIST, ErrorItem } from '@/helpers/errors';
import dayjs from 'dayjs';

export interface HorseShoe {
  id: string;
  horseId?: string;
  type: string;
  dateStart: string;
  dateEnd?: string | null;
  datePredictableEnd: string;
  observations: string;
}

export interface HorseShoeData {
  current: HorseShoe;
  history: HorseShoe[] | null;
}

export interface State {
  horseShoes: Record<string, HorseShoeData>;
}

function initialState() {
  return {
    horseShoes: {}
  };
}

// getters
export type Getters = {
  getByHorseId(state: State): (horseId: string) => HorseShoeData | null;
  getHistoryByHorseId(state: State): (horseId: string) => HorseShoe[] | null;
  getCurrentByHorseId(state: State): (horseId: string) => HorseShoe | null;
  getDuration(): (horseShoe: HorseShoe) => number;
};

const getters: GetterTree<State, RootState> & Getters = {
  getByHorseId: (state) => (horseId) => {
    let horseShoe = null;
    if (horseId in state.horseShoes) horseShoe = state.horseShoes[horseId];
    return horseShoe;
  },
  getHistoryByHorseId: (state) => (horseId) => {
    let horseShoe = null;
    if (horseId in state.horseShoes)
      horseShoe = state.horseShoes[horseId].history;
    return horseShoe;
  },
  getCurrentByHorseId: (state) => (horseId) => {
    let horseShoe = null;
    if (horseId in state.horseShoes)
      horseShoe = state.horseShoes[horseId].current;
    return horseShoe;
  },
  getDuration: () => (horseShoe) => {
    let Difference_In_Time;
    if (!horseShoe.dateEnd) {
      Difference_In_Time = dayjs(horseShoe.datePredictableEnd).diff(
        new Date(horseShoe.dateStart),
        'day'
      );
    } else {
      Difference_In_Time = dayjs(horseShoe.dateEnd).diff(
        new Date(horseShoe.dateStart),
        'day'
      );
    }
    return Difference_In_Time;
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  create(state: S, horseShoe: HorseShoe): void;
  createHistory(
    state: S,
    payload: { horseId: string; horseShoes: HorseShoe[] }
  ): void;
  updateCurrent(state: S, horseShoe: HorseShoe): void;
  update(state: S, horseShoe: HorseShoe): void;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    Object.assign(state, initialState());
  },
  create(state, horseShoe) {
    if (horseShoe.horseId) {
      Vue.set(state.horseShoes, horseShoe.horseId, {
        current: horseShoe,
        history: null
      });
    } else throw 'HorseId Cannot be null';
  },
  createHistory(state, payload) {
    if (payload.horseId) {
      if (payload.horseId in state.horseShoes) {
        let history = state.horseShoes[payload.horseId].history;
        if (!history) {
          history = [];
        }
        history.push(...payload.horseShoes);
      } else {
        Vue.set(state.horseShoes, payload.horseId, {
          current: null,
          history: payload.horseShoes
        });
      }
    } else throw 'Cannot create history without having a current!';
  },
  updateCurrent(state, horseShoe) {
    if (horseShoe.horseId) {
      if (horseShoe.horseId in state.horseShoes) {
        const oldCurrent = state.horseShoes[horseShoe.horseId].current;
        oldCurrent.dateEnd = horseShoe.dateStart;
        let history = state.horseShoes[horseShoe.horseId].history;
        if (!history) {
          history = [];
        }
        history.push(oldCurrent);
        state.horseShoes[horseShoe.horseId].current = horseShoe;
      } else {
        state.horseShoes[horseShoe.horseId] = {
          current: horseShoe,
          history: []
        };
      }
    } else throw 'HorseId Cannot be null';
  },
  update(state, horseShoe) {
    if (horseShoe.horseId && horseShoe.horseId in state.horseShoes) {
      if (horseShoe.id === state.horseShoes[horseShoe.horseId].current.id) {
        state.horseShoes[horseShoe.horseId].current = horseShoe;
        return;
      }
      const history = state.horseShoes[horseShoe.horseId].history;
      if (!history) {
        //TODO Horse Shoe not found, add error
        return;
      }
      const horseShoeIndex = history.findIndex(
        (hsi) => (hsi.id = horseShoe.id)
      );
      if (horseShoeIndex >= 0) {
        history[horseShoeIndex] = horseShoe;
      } else {
        //TODO Horse Shoe not found, add error
      }
    }
  }
};

// actions
export interface Actions {
  addHorseShoeToHorses(
    { commit, dispatch }: ActionContext<State, RootState>,
    payload: { horsesId: string[]; horseShoe: HorseShoe }
  ): Promise<void>;

  loadActualHorseShoeFromHorse(
    { getters, commit }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<HorseShoe | null>;

  loadHorseShoeFromHorse(
    { getters, commit, dispatch }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<HorseShoeData | null>;

  updateHorseShoe(
    { commit }: ActionContext<State, RootState>,
    payload: { horseShoe: HorseShoe }
  ): Promise<HorseShoe | ErrorItem>;
}

const actions: ActionTree<State, RootState> & Actions = {
  addHorseShoeToHorses({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const loadAllHorseHorseShoes: Promise<HorseShoeData>[] = [];
      payload.horsesId.forEach((horseId) => {
        loadAllHorseHorseShoes.push(
          dispatch('loadHorseShoeFromHorse', horseId)
        );
      });
      createHorseShoesForHorses(payload.horsesId, payload.horseShoe)
        .then((obj) => {
          Promise.all(loadAllHorseHorseShoes).then(() => {
            obj.horseShoes.forEach((horseShoe) => {
              commit('updateCurrent', horseShoe);
            });
            obj.feeds.forEach((feed) => {
              dispatch(
                'FeedModule/addFeedInHorse',
                {
                  horseId: feed.horse?.id,
                  feed: feed
                },
                { root: true }
              ).catch((error) => Vue.prototype.$rollbar.error(error));
            });
            obj.notifications.forEach((item) => {
              const time = dayjs(item.end).diff(new Date(), 'day');
              if (time > 0) {
                commit(
                  'HorseModule/addNotification',
                  {
                    horseId: item.horseId,
                    notification: item,
                    module: item.category
                  },
                  { root: true }
                );
              }
            });
            resolve();
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateHorseShoe({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dbEditHorseShoe(payload.horseShoe)
        .then(() => {
          const newHorseShoe = Object.assign({}, payload.horseShoe);
          commit('update', newHorseShoe);
          resolve(newHorseShoe);
        })
        .catch((error) => {
          Vue.prototype.$rollbar.errorr(error);
          reject(ERROR_LIST.HORSESHOE_UPDATE_FAIL);
        });
    });
  },

  loadActualHorseShoeFromHorse({ getters, commit }, horseId) {
    return new Promise((resolve, reject) => {
      const horseShoe = getters.getCurrentByHorseId(horseId);
      if (!horseShoe) {
        loadHorseShoeActual(horseId)
          .then((horseShoeNew) => {
            if (horseShoeNew) commit('create', horseShoeNew);
            resolve(horseShoeNew);
          })
          .catch(() => {
            reject(null);
          });
      } else resolve(horseShoe);
    });
  },
  loadHorseShoeFromHorse({ getters, commit, dispatch }, horseId) {
    return new Promise((resolve, reject) => {
      const horseShoes = getters.getByHorseId(horseId);
      if (!horseShoes || !horseShoes.history) {
        dispatch('loadActualHorseShoeFromHorse', horseId)
          .then((horseShoeActual) => {
            loadHorseShoeHistory(horseId)
              .then((horseShoesHistory) => {
                if (horseShoesHistory && horseShoesHistory.length)
                  commit('createHistory', {
                    horseId: horseId,
                    horseShoes: horseShoesHistory
                  });
                resolve({
                  current: horseShoeActual,
                  history: horseShoesHistory
                });
              })
              .catch(() => {
                reject(null);
              });
          })
          .catch(() => {
            reject(null);
          });
      } else resolve(horseShoes);
    });
  }
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const HorseShoeModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
