
import Vue from 'vue';
import ImageKit from 'imagekit-javascript';

interface Methods {
  forward(): void;
}

interface Props {
  avatar: string | ImageKit.UploadResponse;
  icon: string;
  link: string;
  color: string;
  iconSize: string;
}

interface Computed {
  iconSizeAttr: { [key: string]: string | boolean };
}

export default Vue.extend<Record<string, never>, Methods, Computed, Props>({
  name: 'AvatarImageKit',
  methods: {
    forward() {
      if (this.$props.link) {
        this.$router.push(this.$props.link);
      }
    }
  },
  props: {
    avatar: { type: [String, Object] },
    link: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'primary'
    },
    iconSize: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconSizeAttr() {
      if (this.$props.iconSize) {
        const objAttr: { [key: string]: string | boolean } = {};
        objAttr[this.$props.iconSize] = true;
        return objAttr;
      } else return {};
    }
  }
});
