import { errors } from "faunadb";

export interface Errors {
  GENERIC_ERROR_NO_EXISTS: ErrorItem;
  IMAGE_UPLOAD_FAIL: ErrorItem;
  PLATFORM_CONFIGURATION_MISSING: ErrorItem;
  LOGIN_PASSWORD_INVALID: ErrorItem;
  LOGIN_NOT_CONFIRMED: ErrorItem;
  ACCOUNT_WITH_PROBLEMS: ErrorItem;
  FAUNA_PERMISSION_DENIED: ErrorItem;
  FAUNA_BAD_REQUEST_INVALID_REF: ErrorItem;
  FAUNA_BAD_REQUEST_FUNCTION_ERROR: ErrorItem;
  FAUNA_BAD_REQUEST_TRANSACTION_ABORTED: ErrorItem;
  SPACE_NO_ACCESS: ErrorItem;
  SPACE_CREATE_FAIL: ErrorItem;
  SPACE_CREATE_FAIL_LIMIT: ErrorItem;
  SPACE_CREATE_IMAGE_FAIL: ErrorItem;
  SPACE_INACTIVE: ErrorItem;
  SPACE_ADD_USER_DOES_NOT_EXIST: ErrorItem;
  SPACE_CANCEL_FAIL: ErrorItem;
  HORSE_CREATE_IMAGE_FAIL: ErrorItem;
  HORSE_CREATE_FAIL: ErrorItem;
  HORSE_UPDATE_FAIL: ErrorItem;
  USER_UPDATE_FAIL: ErrorItem;
  HORSE_DELETE_FAIL: ErrorItem;
  HORSE_CREATE_FAIL_LIMIT: ErrorItem;
  STABLE_NO_EXISTS: ErrorItem;
  STABLE_CREATE_FAIL: ErrorItem;
  STABLE_UPDATE_FAIL: ErrorItem;
  STABLE_CREATE_FAIL_LIMIT: ErrorItem;
  HORSESHOE_UPDATE_FAIL: ErrorItem;
  SPORT_ACTIVITY_UPDATE_FAIL: ErrorItem;
  SPORT_ACTIVITY_DELETE_FAIL: ErrorItem;
  CALENDAR_EVENT_DELETE_FAIL: ErrorItem;
  CALENDAR_EVENT_UPDATE_FAIL: ErrorItem;
  HEALTH_REPORT_UPDATE_FAIL: ErrorItem;
  HORSE_NO_EXISTS: ErrorItem;
}

export interface ErrorItem {
  code: number;
  action: 'logout' | 'propagate';
  errorStack?: errors.FaunaError;
}

export const ERROR_LIST: Errors = Object.freeze({
  GENERIC_ERROR_NO_EXISTS: {
    code: 100000,
    action: 'logout'
  },
  IMAGE_UPLOAD_FAIL: {
    code: 100001,
    action: 'propagate'
  },
  LOGIN_PASSWORD_INVALID: {
    code: 100002,
    action: 'propagate'
  },
  LOGIN_NOT_CONFIRMED: {
    code: 100003,
    action: 'propagate'
  },
  ACCOUNT_WITH_PROBLEMS: {
    code: 100004,
    action: 'propagate'
  },
  PLATFORM_CONFIGURATION_MISSING: {
    code: 100004,
    action: 'propagate'
  },
  FAUNA_PERMISSION_DENIED: {
    code: 110000,
    action: 'propagate'
  },
  FAUNA_BAD_REQUEST_INVALID_REF: {
    code: 110001,
    action: 'logout'
  },
  FAUNA_BAD_REQUEST_FUNCTION_ERROR: {
    code: 110002,
    action: 'propagate'
  },
  FAUNA_BAD_REQUEST_TRANSACTION_ABORTED: {
    code: 110003,
    action: 'propagate'
  },
  SPACE_NO_ACCESS: {
    code: 200001,
    action: 'propagate'
  },
  SPACE_CREATE_FAIL: {
    code: 200002,
    action: 'propagate'
  },
  SPACE_CREATE_IMAGE_FAIL: {
    code: 200003,
    action: 'propagate'
  },
  SPACE_INACTIVE: {
    code: 200004,
    action: 'propagate'
  },
  SPACE_CREATE_FAIL_LIMIT: {
    code: 200005,
    action: 'propagate'
  },
  SPACE_ADD_USER_DOES_NOT_EXIST: {
    code: 200006,
    action: 'propagate'
  },
  SPACE_CANCEL_FAIL: {
    code: 200007,
    action: 'propagate'
  },
  STABLE_NO_EXISTS: {
    code: 300001,
    action: 'propagate'
  },
  STABLE_CREATE_FAIL: {
    code: 300002,
    action: 'propagate'
  },
  STABLE_UPDATE_FAIL: {
    code: 300003,
    action: 'propagate'
  },
  STABLE_CREATE_FAIL_LIMIT: {
    code: 300004,
    action: 'propagate'
  },
  HORSE_NO_EXISTS: {
    code: 400001,
    action: 'propagate'
  },
  HORSE_CREATE_FAIL: {
    code: 400002,
    action: 'propagate'
  },
  HORSE_UPDATE_FAIL: {
    code: 400003,
    action: 'propagate'
  },
  HORSE_CREATE_IMAGE_FAIL: {
    code: 400004,
    action: 'propagate'
  },
  HORSE_CREATE_FAIL_LIMIT: {
    code: 400005,
    action: 'propagate'
  },
  HORSE_DELETE_FAIL: {
    code: 400006,
    action: 'propagate'
  },
  USER_UPDATE_FAIL: {
    code: 400007,
    action: 'propagate'
  },
  HORSESHOE_UPDATE_FAIL: {
    code: 500001,
    action: 'propagate'
  },
  HEALTH_REPORT_UPDATE_FAIL: {
    code: 600001,
    action: 'propagate'
  },
  SPORT_ACTIVITY_UPDATE_FAIL: {
    code: 700001,
    action: 'propagate'
  },
  SPORT_ACTIVITY_DELETE_FAIL: {
    code: 700002,
    action: 'propagate'
  },
  CALENDAR_EVENT_DELETE_FAIL: {
    code: 800001,
    action: 'propagate'
  },
  CALENDAR_EVENT_UPDATE_FAIL: {
    code: 800002,
    action: 'propagate'
  }
});

export function getError(code: number): ErrorItem {
  const error = Object.values(ERROR_LIST).find(
    (item: ErrorItem) => item.code === code
  );
  if (error) return error;
  else return ERROR_LIST.GENERIC_ERROR_NO_EXISTS;
}
