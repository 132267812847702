import {
  ActionContext,
  ActionTree,
  CommitOptions,
  DispatchOptions,
  GetterTree,
  Module,
  MutationTree,
  Store as VuexStore
} from "vuex";

import { State as RootState } from "@/store";
import {
  dbCreateHorse,
  dbEditHorse,
  deleteFromFaunaDbCollection
} from "@/helpers/data/fauna-queries";
import { upload } from "@/helpers/image/imagekit-helpers";
import { ERROR_LIST, ErrorItem } from "@/helpers/errors";
import { ModuleItem } from "@/store/constants";
import { Notification } from "@/helpers/notifications";
import Vue from "vue";

export interface Genealogy {
  name: string;
  race: string;
  grandMother: {
    name: string;
    race: string;
    greatGrandMother: {
      name: string;
      race: string;
      greatGreatGrandMother: {
        name: string;
        race: string;
      };
      greatGreatGrandFather: {
        name: string;
        race: string;
      };
    };
    greatGrandFather: {
      name: string;
      race: string;
      greatGreatGrandMother: {
        name: string;
        race: string;
      };
      greatGreatGrandFather: {
        name: string;
        race: string;
      };
    };
  };
  grandFather: {
    name: string;
    race: string;
    greatGrandMother: {
      name: string;
      race: string;
      greatGreatGrandMother: {
        name: string;
        race: string;
      };
      greatGreatGrandFather: {
        name: string;
        race: string;
      };
    };
    greatGrandFather: {
      name: string;
      race: string;
      greatGreatGrandMother: {
        name: string;
        race: string;
      };
      greatGreatGrandFather: {
        name: string;
        race: string;
      };
    };
  };
}

export interface HorseNotification {
  health: Notification[];
  food: Notification[];
  horseshoe: Notification[];
  sport: Notification[];
}

export interface HorseCategoryType {
  name: string;
  i18nCodeName: string;
}

export interface HorseCategories {
  MATRIX: HorseCategoryType;
  DONOR: HorseCategoryType;
  RECEPTOR: HorseCategoryType;
  STALLION: HorseCategoryType;
  CASTRATED: HorseCategoryType;
}

export const HORSE_CATEGORY: HorseCategories = Object.freeze({
  MATRIX: {
    name: 'Matrix',
    i18nCodeName: 'horse-edit-profile-card.category.matrix'
  },
  DONOR: {
    name: 'Donor',
    i18nCodeName: 'horse-edit-profile-card.category.donor'
  },
  RECEPTOR: {
    name: 'Receptor',
    i18nCodeName: 'horse-edit-profile-card.category.receptor'
  },
  STALLION: {
    name: 'Stallion',
    i18nCodeName: 'horse-edit-profile-card.category.stallion'
  },
  CASTRATED: {
    name: 'Castrated',
    i18nCodeName: 'horse-edit-profile-card.category.castrated'
  }
});

export interface HorseWeight {
  weightInDate: string;
  weight: string;
  weighingType: string;
  conclusion: string;
}

export interface Horse {
  id: string;
  name: string;
  avatar?: string;
  sex: string;
  category: string;
  pelage: string;
  owner?: string;
  passport?: number;
  box?: string;
  chip?: number;
  genealogyNumber?: number;
  born?: { date: string; country: string };
  federation?: string;
  stallionNumber?: number;
  height?: number;
  weight?: number;
  race: string;
  genealogy?: {
    mother: Genealogy;
    father: Genealogy;
  };
  photos?: string[];
  notifications?: HorseNotification | null;
  weights?: HorseWeight[];
}

function initialState() {
  return {
    horses: []
  };
}

export interface State {
  horses: Horse[];
}

// getters
export type Getters = {
  getAll(state: State): Horse[];
  getById(state: State): (id: string) => Horse | undefined;
  getByName(state: State): (name: string) => Horse | undefined;
  countAll(state: State): number;
};

const getters: GetterTree<State, RootState> & Getters = {
  getAll: (state) => {
    return state.horses;
  },
  getById: (state) => (id) => {
    return state.horses.find((horse) => horse.id === id);
  },
  getByName: (state) => (name) => {
    return state.horses.find((horse) => horse.name === name);
  },
  countAll: (state) => {
    return state.horses.length;
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  create(state: S, horse: Horse): void;
  update(state: S, horse: Horse): void;
  addNotification(
    state: S,
    payload: {
      horseId: string;
      notification: Notification;
      module: ModuleItem;
    }
  ): void;
  deleteNotification(
    state: S,
    payload: {
      horseId: string;
      notificationId: string;
      module: ModuleItem | null;
    }
  ): boolean;
  deleteHorse(state: S, payload: { horseId: string }): void;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    // acquire initial state
    Object.assign(state, initialState());
  },
  create(state, horse) {
    state.horses.push(horse);
  },
  update(state, horse) {
    const horseData = horse;
    const horseIndex = state.horses.findIndex((hi) => hi.id === horseData.id);
    if (horseIndex >= 0) {
      Vue.set(state.horses, horseIndex, horse);
    } else {
      //TODO Horse not found, add error
    }
  },
  deleteHorse(state, payload) {
    const horseIndex = state.horses.findIndex(
      (hi) => hi.id === payload.horseId
    );
    if (horseIndex >= 0) {
      Vue.set(state.horses, horseIndex, null);
      state.horses.splice(horseIndex, 1);
    } else {
      //TODO Horse not found, add error
    }
  },
  addNotification(state, payload) {
    const horse = state.horses.find((hi) => hi.id === payload.horseId);
    if (horse) {
      if (horse.notifications) {
        const notifications =
          horse.notifications[
            payload.module.name.toLowerCase() as keyof HorseNotification
          ];
        const notificationIndex = notifications.findIndex(
          (not) => not.id === payload.notification.id
        );
        if (notificationIndex >= 0) {
          notifications[notificationIndex] = payload.notification;
        } else {
          notifications.push(payload.notification);
        }
      } else {
        horse.notifications = {
          health: [],
          food: [],
          horseshoe: [],
          sport: []
        };
        horse.notifications[
          payload.module.name.toLowerCase() as keyof HorseNotification
        ].push(payload.notification);
      }
    } else {
      //TODO Horse not found, add error
    }
  },
  deleteNotification(state, payload) {
    const horse = state.horses.find((not) => not.id === payload.horseId);
    if (horse) {
      if (horse.notifications) {
        let notifications: Notification[] = [];
        let notificationIndex = -1;
        if (payload.module) {
          notifications =
            horse.notifications[
              payload.module.name.toLowerCase() as keyof HorseNotification
            ];
          notificationIndex = notifications.findIndex(
            (not) => not.id === payload.notificationId
          );
        } else {
          notifications = horse.notifications.food;
          notificationIndex = notifications.findIndex(
            (not) => not.id === payload.notificationId
          );
          if (notificationIndex === -1) {
            notifications = horse.notifications.health;
            notificationIndex = notifications.findIndex(
              (not) => not.id === payload.notificationId
            );
          }
          if (notificationIndex === -1) {
            notifications = horse.notifications.horseshoe;
            notificationIndex = notifications.findIndex(
              (not) => not.id === payload.notificationId
            );
          }
          if (notificationIndex === -1) {
            notifications = horse.notifications.sport;
            notificationIndex = notifications.findIndex(
              (not) => not.id === payload.notificationId
            );
          }
        }
        if (notificationIndex >= 0) {
          notifications.splice(notificationIndex, 1);
          return true;
        }
      }
    }
    return false;
  }
};

// actions
export interface Actions {
  createHorse(
    { rootGetters, getters, commit, dispatch }: ActionContext<State, RootState>,
    payload: { horse: Horse; avatar: File; stableId: string; spaceId: string }
  ): Promise<Horse | ErrorItem>;

  updateHorse(
    { commit }: ActionContext<State, RootState>,
    payload: { horse: Horse }
  ): Promise<Horse | ErrorItem>;

  deleteHorse(
    { commit, dispatch }: ActionContext<State, RootState>,
    payload: { horseId: string; spaceId: string }
  ): Promise<void | ErrorItem>;

  uploadImage(
    { commit }: ActionContext<State, RootState>,
    payload: { horse: Horse; imageToUpload: File }
  ): Promise<Horse | ErrorItem>;

  setAvatar(
    { commit }: ActionContext<State, RootState>,
    payload: { horse: Horse; newAvatar: string }
  ): Promise<Horse | ErrorItem>;

  addNotification(
    { commit }: ActionContext<State, RootState>,
    payload: {
      horseId: string;
      notification: Notification;
      module: ModuleItem;
    }
  ): void;
}

const actions: ActionTree<State, RootState> & Actions = {
  createHorse({ rootGetters, getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dbCreateHorse(payload.horse, payload.stableId, payload.spaceId)
        .then((horse) => {
          commit('SpaceModule/increaseHorse', payload.spaceId, { root: true });
          if (payload.avatar) {
            upload(payload.avatar, {
              tags: 'horse-avatar',
              folder: horse.id
            })
              .then((result) => {
                horse.avatar = result.url;
                horse.photos = [];
                horse.photos.push(result.url);
                dbEditHorse(horse)
                  .then(() => {
                    commit('create', horse);
                    dispatch(
                      'StableModule/addHorse',
                      {
                        stableId: payload.stableId,
                        horse: horse
                      },
                      { root: true }
                    );
                    resolve(horse);
                  })
                  .catch((error) => {
                    Vue.prototype.$rollbar.error(error);
                    //TODO delete image in ImageKit, it requires to be executed from backend
                    commit('create', horse);
                    dispatch(
                      'StableModule/addHorse',
                      {
                        stableId: payload.stableId,
                        horse: horse
                      },
                      { root: true }
                    );
                    reject(ERROR_LIST.HORSE_CREATE_IMAGE_FAIL);
                  });
              })
              .catch(() => {
                commit('create', horse);
                dispatch(
                  'StableModule/addHorse',
                  {
                    stableId: payload.stableId,
                    horse: horse
                  },
                  { root: true }
                );
                reject(ERROR_LIST.HORSE_CREATE_IMAGE_FAIL);
              });
          } else {
            commit('create', horse);
            dispatch(
              'StableModule/addHorse',
              {
                stableId: payload.stableId,
                horse: horse
              },
              { root: true }
            );
            resolve(horse);
          }
        })
        .catch((error) => {
          if (error === ERROR_LIST.FAUNA_PERMISSION_DENIED) {
            const horseCount = getters['countAll'];
            const space = rootGetters['UserModule/getSpace'];
            const limit = space.plan ? space.plan.horse : 1000;
            if (horseCount >= limit) {
              reject(ERROR_LIST.HORSE_CREATE_FAIL_LIMIT);
            }
          }
          reject(ERROR_LIST.HORSE_CREATE_FAIL);
        });
    });
  },
  updateHorse({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dbEditHorse(payload.horse)
        .then(() => {
          const horseNew = Object.assign({}, payload.horse);
          commit('update', horseNew);
          resolve(horseNew);
        })
        .catch((error) => {
          Vue.prototype.$rollbar.error(error);
          reject(ERROR_LIST.HORSE_UPDATE_FAIL);
        });
    });
  },
  deleteHorse({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      deleteFromFaunaDbCollection(payload.horseId, 'horses')
        .then(() => {
          dispatch(
            'StableModule/removeHorse',
            {
              horseId: payload.horseId
            },
            { root: true }
          ).then(() => {
            commit('SpaceModule/decreaseHorse', payload.spaceId, {
              root: true
            });
            commit('deleteHorse', {
              horseId: payload.horseId
            });
            resolve();
          });
        })
        .catch((error) => {
          Vue.prototype.$rollbar.error(error);
          reject(ERROR_LIST.HORSE_UPDATE_FAIL);
        });
    });
  },
  uploadImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.imageToUpload) {
        upload(payload.imageToUpload, {
          tags: 'horse-avatar',
          folder: payload.horse.id
        })
          .then((result) => {
            if (!Array.isArray(payload.horse.photos)) {
              payload.horse.photos = [];
            }
            payload.horse.photos.push(result.url);
            dbEditHorse(payload.horse)
              .then(() => {
                commit('update', payload.horse);
                resolve(payload.horse);
              })
              .catch((error) => {
                Vue.prototype.$rollbar.error(error);
                //TODO Delete image
                reject(ERROR_LIST.HORSE_CREATE_IMAGE_FAIL);
              });
          })
          .catch((error) => {
            Vue.prototype.$rollbar.error(error);
            reject(ERROR_LIST.HORSE_CREATE_IMAGE_FAIL);
          });
      } else {
        reject(ERROR_LIST.HORSE_UPDATE_FAIL);
      }
    });
  },
  setAvatar({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.newAvatar) {
        payload.horse.avatar = payload.newAvatar;
        dbEditHorse(payload.horse)
          .then(() => {
            commit('update', payload.horse);
            resolve(payload.horse);
          })
          .catch((error) => {
            Vue.prototype.$rollbar.error(error);
            //TODO Delete image
            reject(ERROR_LIST.HORSE_UPDATE_FAIL);
          });
      } else {
        reject(ERROR_LIST.HORSE_UPDATE_FAIL);
      }
    });
  },
  addNotification({ commit }, payload) {
    commit('addNotification', payload);
  }
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const HorseModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
