import { Horse } from "@/store/modules/horse";
import {
  ActionContext,
  ActionTree,
  CommitOptions,
  DispatchOptions,
  GetterTree,
  Module,
  MutationTree,
  Store as VuexStore
} from "vuex";

import { State as RootState } from "@/store";
import { createStable, editStable } from "@/helpers/data/fauna-queries";
import { ERROR_LIST, ErrorItem } from "@/helpers/errors";
import Vue from "vue";

export interface Address {
  line1: string;
  line2: string;
  postCode: string;
  city: string;
  state: string;
  country: string;
}

export interface Professional {
  name: string;
  type: 'veterinary' | 'blacksmith' | 'rider' | 'keeper' | 'horse-breaker';
  address?: Address;
  phones?: { number: string; type: string }[];
  emails?: { email: string; type: string }[];
}

export interface Stable {
  id: string;
  name: string;
  horses: string[];
  address?: Address;
  phone?: string;
  professionals?: Professional[];
}

//state
export interface State {
  stables: Stable[];
}

function initialState() {
  return {
    stables: []
  };
}

// getters
export type Getters = {
  getAll(state: State): Stable[];
  getById(state: State): (id: string) => Stable | undefined;
  getByHorseId(state: State): (horseId: string) => Stable | undefined;
  getByName(state: State): (name: string) => Stable | undefined;
  getHorseIdsById(state: State): (name: string) => string[] | null;
  countAll(state: State): number;
};

const getters: GetterTree<State, RootState> & Getters = {
  getAll: (state) => {
    return state.stables;
  },
  getById: (state) => (id) => {
    return state.stables.find((stable: Stable) => stable.id === id);
  },
  getByHorseId: (state) => (horseId) => {
    return state.stables.find((stable: Stable) =>
      stable.horses.find((horse: string) => horse === horseId)
    );
  },
  getByName: (state) => (name) => {
    return state.stables.find((stable: Stable) => stable.name === name);
  },
  getHorseIdsById: (state) => (id) => {
    const stable = state.stables.find(
      (stableItem: Stable) => stableItem.id === id
    );
    if (stable && stable.horses) {
      return stable.horses;
    }
    return null;
  },
  countAll: (state) => {
    return state.stables.length;
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  create(state: S, stable: Stable): void;
  addHorse(state: S, payload: { stable: Stable; horse: Horse }): void;
  removeHorse(state: S, payload: { stable: Stable; horseId: string }): void;
  update(state: S, stable: Stable): void;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    // acquire initial state
    Object.assign(state, initialState());
  },
  create(state, stable) {
    state.stables.push(stable);
  },
  addHorse(state, payload) {
    if (!payload.stable.horses) {
      payload.stable.horses = [];
    }
    payload.stable.horses.push(payload.horse.id);
  },
  removeHorse(state, payload) {
    const stableIndex = state.stables.findIndex(
      (si) => si.id === payload.stable.id
    );
    if (stableIndex >= 0) {
      const horseIndex = state.stables[stableIndex].horses.findIndex(
        (horseId) => horseId === payload.horseId
      );
      if (horseIndex >= 0) {
        state.stables[stableIndex].horses.splice(horseIndex, 1);
      }
    }
  },
  update(state, stable) {
    const stableData = stable;
    const stableIndex = state.stables.findIndex(
      (si) => si.id === stableData.id
    );
    if (stableIndex >= 0) {
      state.stables[stableIndex] = stable;
    } else {
      //TODO Stable not found, add error
    }
  }
};

// actions
export interface Actions {
  addHorse(
    { getters, commit }: ActionContext<State, RootState>,
    payload: { stableId: number; horse: Horse }
  ): Stable | never;

  removeHorse(
    { getters, commit, dispatch }: ActionContext<State, RootState>,
    payload: { horseId: string }
  ): Promise<void | ErrorItem>;

  createStable(
    { rootGetters, getters, commit }: ActionContext<State, RootState>,
    stable: Stable
  ): Promise<Stable>;

  updateStable(
    { commit }: ActionContext<State, RootState>,
    payload: { stable: Stable }
  ): Promise<Stable | ErrorItem>;
}

const actions: ActionTree<State, RootState> & Actions = {
  addHorse({ getters, commit }, payload) {
    const stable: Stable = getters.getById(payload.stableId);
    if (stable) {
      commit('addHorse', { stable: stable, horse: payload.horse });
      return stable;
    }
    throw new Error('No Stable with this ID');
  },
  removeHorse({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const stable: Stable = getters.getByHorseId(payload.horseId);
      if (stable) {
        commit('removeHorse', { stable: stable, horseId: payload.horseId });
        dispatch(
          'StableModule/updateStable',
          {
            stable: stable
          },
          { root: true }
        )
          .then(() => {
            resolve();
          })
          .catch((error) => {
            Vue.prototype.$rollbar.error(error);
            reject(error);
          });
      }
    });
  },
  createStable({ rootGetters, getters, commit }, stable) {
    return new Promise((resolve, reject) => {
      const space = rootGetters['UserModule/getSpace'];
      createStable(stable, space.id)
        .then((stable) => {
          commit('SpaceModule/increaseStable', space.id, { root: true });
          commit('create', stable);
          resolve(stable);
        })
        .catch((error) => {
          if (error === ERROR_LIST.FAUNA_PERMISSION_DENIED) {
            const stableCount = getters['countAll'];
            const limit = space.plan ? space.plan.stable : 1000;
            if (stableCount >= limit) {
              reject(ERROR_LIST.STABLE_CREATE_FAIL_LIMIT);
            }
          }
          reject(ERROR_LIST.STABLE_CREATE_FAIL);
        });
    });
  },
  updateStable({ commit }, payload) {
    return new Promise((resolve, reject) => {
      editStable(payload.stable)
        .then(() => {
          const stableNew = Object.assign({}, payload.stable);
          commit('update', stableNew);
          resolve(stableNew);
        })
        .catch((error) => {
          Vue.prototype.$rollbar.error(error);
          reject(ERROR_LIST.STABLE_UPDATE_FAIL);
        });
    });
  }
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const StableModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
