
import Vue from "vue";
import { mapState } from "vuex";
import { getError } from "@/helpers/errors";
import { logout } from "@/helpers/utils";
import AvatarImageKit from "@/components/AvatarImageKit.vue";
import { TranslateResult } from "vue-i18n";
import { Role, User } from "@/store/modules/user";
import { Space } from "@/store/modules/space";
import Notifications from "@/components/Notifications.vue";
import { HELP_CENTER_URL } from "@/store/constants";
import update from "@/helpers/update";
import {
  mdiAccountCircle,
  mdiAccountEdit,
  mdiBarn,
  mdiCellphoneArrowDown,
  mdiChessKnight,
  mdiHelpCircle,
  mdiHome,
  mdiLogout,
  mdiMessageOutline,
  mdiSync
} from "@mdi/js";

interface Data {
  drawer: boolean;
  mini: boolean;
  error: {
    dialog: boolean;
    title: TranslateResult;
    description: TranslateResult;
    code: number;
  };
}

export interface Method {
  logout(): void;
  cleanError(): void;
  openChat(): void;
}

interface Computed {
  menuSpaceSelected: {
    icon: string;
    label: TranslateResult;
    link: string;
  }[];
  menuNoSpaceSelected: {
    icon: string;
    label: TranslateResult;
    link: string;
  }[];
  publicPage: boolean;
  userRole: Role;
  currentSpace: Space;
  user: User;
  helpCenter: string | undefined;
  mdiHelpCircle: string;
  mdiCellphoneArrowDown: string;
  mdiMessageOutline: string;
  mdiAccountEdit: string;
  mdiLogout: string;
  mdiAccountCircle: string;
  mdiSync: string;
  mdiBarn: string;
}

export default Vue.extend<Data, Method, Computed, Record<string, never>>({
  components: { Notifications, AvatarImageKit },
  mixins: [update],
  data: () => ({
    drawer: true,
    mini: true,
    error: { dialog: false, title: '', description: '', code: 0 }
  }),
  methods: {
    logout() {
      logout();
    },
    cleanError() {
      this.error = {
        dialog: false,
        title: '',
        description: '',
        code: 0
      };
    },
    openChat() {
      this.$Tawk.maximize();
    }
  },
  computed: {
    ...mapState('UserModule', ['user', 'currentSpace']),

    mdiHelpCircle() {
      return mdiHelpCircle;
    },
    mdiCellphoneArrowDown() {
      return mdiCellphoneArrowDown;
    },
    mdiMessageOutline() {
      return mdiMessageOutline;
    },
    mdiAccountEdit() {
      return mdiAccountEdit;
    },
    mdiLogout() {
      return mdiLogout;
    },
    mdiAccountCircle() {
      return mdiAccountCircle;
    },
    mdiSync() {
      return mdiSync;
    },
    mdiBarn() {
      return mdiBarn;
    },
    menuSpaceSelected() {
      let spaceURL = '';
      if (this.currentSpace) spaceURL = '/' + this.currentSpace.id;
      return [
        {
          icon: mdiHome,
          label: this.$t('app.menu.home'),
          link: spaceURL
        },
        {
          icon: mdiChessKnight,
          label: this.$t('app.menu.horses'),
          link: spaceURL + '/horses'
        }
        // {
        //   icon: mdiCalendar,
        //   label: this.$t('app.menu.calendar'),
        //   link: spaceURL + '/calendar'
        // }
      ];
    },
    menuNoSpaceSelected() {
      return [
        {
          icon: mdiBarn,
          label: this.$t('app.menu.spaces'),
          link: '/'
        }
      ];
    },
    publicPage() {
      return !this.$route.meta?.requiresAuth;
    },
    userRole() {
      return this.$store.getters['UserModule/getUserRole'];
    },
    helpCenter() {
      return HELP_CENTER_URL;
    }
  },
  watch: {
    $route(to) {
      if (to.query && to.query.error) {
        const error = getError(Number(to.query.error));
        this.error = {
          dialog: true,
          title: this.$t('errors.' + error.code + '.title'),
          description: this.$t('errors.' + error.code + '.description'),
          code: error.code
        };
        const query = Object.assign({}, this.$route.query);
        delete query.error;
        this.$router.replace({ query });
      }
    }
  }
});
