import { MODULE_TYPES, ModuleItem } from '@/store/constants';
import {
  deleteNotification as deleteNotificationFauna,
  loadNotificationBySpace,
  markAsReadNotification as markAsReadNotificationFauna
} from '@/helpers/data/fauna-queries';
import dayjs, { UnitTypeLong } from 'dayjs';
import store from '@/store';
import { translate } from '@/helpers/utils';
import { DataType, FOOD_TYPES } from '@/helpers/types';
import { TranslateResult } from 'vue-i18n';
import Vue from 'vue';

export interface NotificationData {
  start: {
    time: number;
    unit: UnitTypeLong;
  };
  period: {
    interval: number;
    unit: UnitTypeLong;
  };
}

export type NotificationView = {
  text: TranslateResult;
  type: string;
  icon: string;
  original: Notification;
};

export interface Notification {
  id: string;
  category: ModuleItem;
  subCategory: string; //TODO derive as a sub module?
  data?: DataType;
  start: Date;
  end: Date;
  read: boolean;
  notificationPeriod: NotificationData;
  userName: string;
  horseId?: string | null;
  stableId?: string | null;
  userId?: string | null;
  originated?: { collection: string | null; id: string };
}

export const loadNotifications = function (spaceId: string): void {
  loadNotificationBySpace(spaceId)
    .then((notifications) => {
      if (notifications) {
        notifications.forEach((item) => {
          if (item.horseId) {
            store.commit('HorseModule/addNotification', {
              horseId: item.horseId,
              notification: item,
              module: item.category
            });
          } else if (item.stableId) {
            throw new Error('Stable notification is not implemented');
          } else {
            throw new Error('Space notification is not implemented');
          }
        });
      }
    })
    .catch(() => {
      //quite error?
    });
};

export const markAsReadNotification = function (
  notification: Notification
): void {
  markAsReadNotificationFauna(notification.id)
    .then(() => {
      notification.read = true;
    })
    .catch(() => {
      Vue.prototype.$rollbar.error(
        `Error: Not able to set notification(${notification.id}) as read`
      );
    });
};

export const deleteNotification = function (notification: Notification): void {
  deleteNotificationFauna(notification.id)
    .then(() => {
      store.commit('HorseModule/deleteNotification', {
        horseId: notification.horseId,
        notificationId: notification.id,
        module: notification.category
      });
    })
    .catch(() => {
      Vue.prototype.$rollbar.error(
        `Error: Not able to delete notification(${notification.id})`
      );
    });
};

export const getNotificationView = function (
  item: Notification
): NotificationView | null {
  const time = dayjs(item.end).diff(new Date(), 'day');
  if (time < 0) {
    // notification is old and should silence mark as read
    markAsReadNotification(item);
  }
  switch (item.category.name) {
    case MODULE_TYPES.FOOD.name: {
      return getFoodNotificationView(item);
    }
    case MODULE_TYPES.HORSESHOE.name: {
      return getHorseShoeNotificationView(item);
    }
    case MODULE_TYPES.HEALTH.name: {
      return getHealthNotificationView(item);
    }
    case MODULE_TYPES.SPORT.name: {
      return getSportNotificationView(item);
    }
  }
  return null;
};

const getFoodNotificationView = function (
  item: Notification
): NotificationView | null {
  const time = dayjs(item.end).diff(new Date(), 'day');
  switch (item.subCategory) {
    case 'SUPPLEMENT': {
      if (time > 0) {
        return {
          text: translate('notification.food.supplement.endInDays', {
            time: {
              value: dayjs(item.end).fromNow(),
              code: false
            }
          }),
          icon: FOOD_TYPES.SUPPLEMENT.icon,
          type: 'info',
          original: item
        };
      } else if (time == 0) {
        return {
          text: translate('notification.food.supplement.endToday'),
          icon: FOOD_TYPES.SUPPLEMENT.icon,
          type: 'warning',
          original: item
        };
      } else {
        return {
          text: translate('notification.food.supplement.endedInDays', {
            time: {
              value: dayjs(item.end).toNow(),
              code: false
            }
          }),
          icon: FOOD_TYPES.SUPPLEMENT.icon,
          type: 'info',
          original: item
        };
      }
    }
  }
  return null;
};

const getSportNotificationView = function (
  item: Notification
): NotificationView | null {
  const time = dayjs(item.end).diff(new Date(), 'day');
  switch (item.subCategory) {
    case 'ACTIVITY_COMING_UP': {
      if (time > 0) {
        return {
          text: translate('notification.sport.activity.coming-up.endInDays', {
            time: {
              value: dayjs(item.end).fromNow(),
              code: false
            }
          }),
          icon: MODULE_TYPES.SPORT.icon,
          type: 'info',
          original: item
        };
      } else if (time == 0) {
        return {
          text: translate('notification.sport.activity.coming-up.endToday'),
          icon: MODULE_TYPES.SPORT.icon,
          type: 'warning',
          original: item
        };
      } else {
        return {
          text: translate('notification.sport.activity.coming-up.endedInDays', {
            time: {
              value: dayjs(item.end).toNow(),
              code: false
            }
          }),
          icon: MODULE_TYPES.SPORT.icon,
          type: 'info',
          original: item
        };
      }
    }
  }
  return null;
};

const getHorseShoeNotificationView = function (
  item: Notification
): NotificationView | null {
  const time = dayjs(item.end).diff(new Date(), 'day');
  switch (item.subCategory) {
    case 'PREDICABLE_REPLACEMENT': {
      if (time > 0) {
        return {
          text: translate('notification.horseShoe.replaceHorseshoe.endInDays', {
            time: {
              value: dayjs(item.end).fromNow(),
              code: false
            }
          }),
          icon: MODULE_TYPES.HORSESHOE.icon,
          type: 'info',
          original: item
        };
      } else if (time == 0) {
        return {
          text: translate('notification.horseShoe.replaceHorseshoe.endToday'),
          icon: MODULE_TYPES.HORSESHOE.icon,
          type: 'warning',
          original: item
        };
      } else {
        return {
          text: translate(
            'notification.horseShoe.replaceHorseshoe.endedInDays',
            {
              time: {
                value: dayjs(item.end).toNow(),
                code: false
              }
            }
          ),
          icon: MODULE_TYPES.HORSESHOE.icon,
          type: 'info',
          original: item
        };
      }
    }
  }
  return null;
};

const getHealthNotificationView = function (
  item: Notification
): NotificationView | null {
  const time = dayjs(item.end).diff(new Date(), 'day');
  switch (item.subCategory) {
    case 'PREDICABLE_VACCINE_REPLACEMENT': {
      if (time > 0) {
        return {
          text: translate(
            'notification.health.vaccine.replaceVaccine.endInDays',
            {
              time: {
                value: dayjs(item.end).fromNow(),
                code: false
              }
            }
          ),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'info',
          original: item
        };
      }
      if (time == 0) {
        return {
          text: translate(
            'notification.health.vaccine.replaceVaccine.endToday'
          ),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'warning',
          original: item
        };
      } else {
        return {
          text: translate(
            'notification.health.vaccine.replaceVaccine.endedInDays',
            {
              time: {
                value: dayjs(item.end).toNow(),
                code: false
              }
            }
          ),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'info',
          original: item
        };
      }
    }
    case 'PREDICABLE_VERMIFUGE_REPLACEMENT': {
      if (time > 0) {
        return {
          text: translate(
            'notification.health.vermifuge.replaceVermifuge.endInDays',
            {
              time: {
                value: dayjs(item.end).fromNow(),
                code: false
              }
            }
          ),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'info',
          original: item
        };
      }
      if (time == 0) {
        return {
          text: translate(
            'notification.health.vermifuge.replaceVermifuge.endToday'
          ),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'warning',
          original: item
        };
      } else {
        return {
          text: translate(
            'notification.health.vermifuge.replaceVermifuge.endedInDays',
            {
              time: {
                value: dayjs(item.end).toNow(),
                code: false
              }
            }
          ),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'info',
          original: item
        };
      }
    }
    case 'PREDICABLE_EIA_REPLACEMENT': {
      if (time > 0) {
        return {
          text: translate('notification.health.eia.replaceEIA.endInDays', {
            time: {
              value: dayjs(item.end).fromNow(),
              code: false
            }
          }),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'info',
          original: item
        };
      }
      if (time == 0) {
        return {
          text: translate('notification.health.eia.replaceEIA.endToday'),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'warning',
          original: item
        };
      } else {
        return {
          text: translate('notification.health.eia.replaceEIA.endedInDays', {
            time: {
              value: dayjs(item.end).toNow(),
              code: false
            }
          }),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'info',
          original: item
        };
      }
    }
    case 'PREDICABLE_GLANDERS_REPLACEMENT': {
      if (time > 0) {
        return {
          text: translate(
            'notification.health.glanders.replaceGlanders.endInDays',
            {
              time: {
                value: dayjs(item.end).fromNow(),
                code: false
              }
            }
          ),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'info',
          original: item
        };
      }
      if (time == 0) {
        return {
          text: translate(
            'notification.health.glanders.replaceGlanders.endToday'
          ),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'warning',
          original: item
        };
      } else {
        return {
          text: translate(
            'notification.health.glanders.replaceGlanders.endedInDays',
            {
              time: {
                value: dayjs(item.end).toNow(),
                code: false
              }
            }
          ),
          icon: MODULE_TYPES.HEALTH.icon,
          type: 'info',
          original: item
        };
      }
    }
  }
  return null;
};
