import {
  ActionContext,
  ActionTree,
  CommitOptions,
  DispatchOptions,
  GetterTree,
  Module,
  MutationTree,
  Store as VuexStore
} from 'vuex';

import { State as RootState } from '@/store';
import Vue from 'vue';
import {
  dbCreateActivityCalendarEventProgram,
  dbEditCalendarEvent,
  dbLoadActivityCalendarEventFromHorse,
  dbLoadActivityCalendarEventFromSportActivity,
  deleteFromFaunaDbCollection
} from '@/helpers/data/fauna-queries';
import { ERROR_LIST, ErrorItem } from '@/helpers/errors';

export interface CalendarEvent {
  id: string;
  horseId?: string;
  referenceId?: string;
  name: string;
  moduleName: string;
  feature?: string;
  editable: boolean;
  color: string;
  icon: string;
  start: string;
  end?: string;
  details: string;
  calendarMap?: CalendarMap[];
  timed: boolean;
}

export interface CalendarMap {
  key: string;
  value: string;
}

export interface State {
  calendarEventsMap: Record<string, CalendarEvent[]>;
}

function initialState() {
  return {
    calendarEventsMap: {}
  };
}

// getters
export type Getters = {
  getCalendarEventsByHorseId(
    state: State
  ): (horseId: string) => CalendarEvent[];
};

const getters: GetterTree<State, RootState> & Getters = {
  getCalendarEventsByHorseId: (state) => (horseId) => {
    // const calendarEvents = [];
    let calendarEvents = null;
    if (horseId in state.calendarEventsMap)
      calendarEvents = state.calendarEventsMap[horseId];
    // calendarEvents.push(state.calendarEventsMap[horseId].calendarEvents);
    return calendarEvents ? calendarEvents : [];
    // return calendarEvents;
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  addHorseCalendarEvents(
    state: S,
    payload: { horseId: string; calendarEvents: CalendarEvent[] }
  ): void;
  addCalendarEventInHorse(
    state: S,
    payload: { horseId: string; calendarEvent: CalendarEvent }
  ): void;
  updateCalendarEvent(state: S, calendarEvent: CalendarEvent): void;
  deleteCalendarEvent(
    state: S,
    payload: { horseId: string; calendarId: string }
  ): void;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    Object.assign(state, initialState());
  },
  addHorseCalendarEvents(state, payload) {
    if (payload.horseId) {
      Vue.set(state.calendarEventsMap, payload.horseId, payload.calendarEvents);
    } else throw 'HorseId Cannot be null';
  },
  addCalendarEventInHorse(state, payload) {
    if (payload.horseId) {
      if (payload.horseId in state.calendarEventsMap) {
        state.calendarEventsMap[payload.horseId].push(payload.calendarEvent);
      } else {
        Vue.set(state.calendarEventsMap, payload.horseId, [
          payload.calendarEvent
        ]);
      }
    } else throw 'HorseId Cannot be null';
  },
  updateCalendarEvent(state, calendarEvent) {
    if (calendarEvent.horseId) {
      if (calendarEvent.horseId in state.calendarEventsMap) {
        const events = state.calendarEventsMap[calendarEvent.horseId];
        const index = events.findIndex(
          (ce) => ce.referenceId === calendarEvent.referenceId
        );
        events[index] = calendarEvent;
        Vue.set(state.calendarEventsMap, calendarEvent.horseId, events);
      } else {
        throw 'Cannot update an object that does not exists';
      }
    } else throw 'HorseId Cannot be null';
  },
  deleteCalendarEvent(state, payload) {
    if (payload.horseId in state.calendarEventsMap) {
      if (!state.calendarEventsMap[payload.horseId]) {
        return;
      }
      const index = state.calendarEventsMap[payload.horseId].findIndex(
        (ce) => ce.referenceId === payload.calendarId
      );
      if (index >= 0) {
        state.calendarEventsMap[payload.horseId].splice(index, 1);
      }
    }
  }
};

// actions
export interface Actions {
  addWCalendarEventToHorse(
    { commit }: ActionContext<State, RootState>,
    payload: {
      horseId: string;
      referenceId: string;
      calendarEvent: CalendarEvent;
    }
  ): Promise<void>;

  updateCalendarEvent(
    { commit }: ActionContext<State, RootState>,
    payload: { event: CalendarEvent }
  ): Promise<CalendarEvent | ErrorItem>;

  loadCalendarEventsFromHorse(
    { getters, commit }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<CalendarEvent[] | null>;

  deleteCalendarEventByReferenceId(
    { commit }: ActionContext<State, RootState>,
    payload: { horseId: string; eventId: string }
  ): Promise<void>;
}

const actions: ActionTree<State, RootState> & Actions = {
  addWCalendarEventToHorse({ commit }, payload) {
    return new Promise((resolve) => {
      dbCreateActivityCalendarEventProgram(
        payload.horseId,
        payload.referenceId,
        payload.calendarEvent
      ).then((calendarEvent) => {
        commit('addCalendarEventInHorse', {
          horseId: payload.horseId,
          calendarEvent: calendarEvent
        });
        resolve();
      });
    });
  },
  loadCalendarEventsFromHorse({ getters, commit }, horseId) {
    return new Promise((resolve, reject) => {
      const calendarEvents = getters.getCalendarEventsByHorseId(horseId);
      if (!calendarEvents || !calendarEvents.length) {
        dbLoadActivityCalendarEventFromHorse(horseId)
          .then((responseCalendarEvents) => {
            if (responseCalendarEvents && responseCalendarEvents.length) {
              responseCalendarEvents.forEach((calendarEvent) => {
                commit('addCalendarEventInHorse', {
                  horseId: calendarEvent.horseId,
                  calendarEvent: calendarEvent
                });
              });
            }
            resolve(responseCalendarEvents);
          })
          .catch(() => {
            reject(null);
          });
      } else {
        resolve(calendarEvents);
      }
    });
  },
  updateCalendarEvent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dbEditCalendarEvent(payload.event)
        .then((updatedCalendarEvent) => {
          commit('updateCalendarEvent', payload.event);
          resolve(updatedCalendarEvent);
        })
        .catch((error) => {
          Vue.prototype.$rollbar.errorr(error);
          reject(ERROR_LIST.SPORT_ACTIVITY_UPDATE_FAIL);
        });
    });
  },
  deleteCalendarEventByReferenceId({ commit }, payload) {
    return new Promise((resolve, reject) => {
      deleteFromFaunaDbCollection(payload.eventId, 'calendarEvents')
        .then(() => {
          commit('deleteCalendarEvent', {
            calendarId: payload.eventId,
            horseId: payload.horseId
          });
          resolve();
        })
        .catch(() => {
          Vue.prototype.$rollbar.error(
            `Error: Not able to delete calendar event(${payload.eventId})`
          );
          reject(ERROR_LIST.CALENDAR_EVENT_DELETE_FAIL);
        });
    });
  }
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const CalendarModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
