import {
  Store as VuexStore,
  GetterTree,
  MutationTree,
  CommitOptions,
  DispatchOptions,
  Module,
  ActionContext,
  ActionTree
} from 'vuex';

import { State as RootState } from '@/store';
import dayjs from 'dayjs';
import { Horse } from '@/store/modules/horse';
import { Stable } from '@/store/modules/stable';
import { FOOD_UPDATE_FOOD_ITEM, ModuleItem } from '@/store/constants';
import {
  loadSystemEventBySpace,
  updateFoodRemoveSupplement
} from '@/helpers/data/fauna-queries';
import Vue from 'vue';

export interface SystemEvent {
  id: string;
  name: string;
  description: string;
  category: ModuleItem;
  subCategory: string; //TODO derive as a sub module?
  start: Date;
  end: Date;
  action?: string; //TODO should be a list of function how to do it properly?
  actionData?: Record<string, string>;
  userName: string;
  horse?: Horse;
  stable?: Stable;
  originated?: { collection: string | null; id: string };
}

export interface State {
  SystemEvents: SystemEvent[];
}

function initialState() {
  return {
    SystemEvents: []
  };
}

const state: State = {
  SystemEvents: []
};

// getters
export type Getters = {
  getAll(state: State): SystemEvent[];
  getById(state: State): (id: string) => SystemEvent | undefined;
  getByStableId(state: State): (spaceId: string) => SystemEvent | undefined;
  getByHorseId(state: State): (horseId: string) => SystemEvent | undefined;
};

const getters: GetterTree<State, RootState> & Getters = {
  getAll: (state) => {
    return state.SystemEvents;
  },
  getById: (state) => (id) => {
    return state.SystemEvents.find(
      (SystemEvent: SystemEvent) => SystemEvent.id === id
    );
  },
  getByStableId: (state) => (stableId) => {
    return state.SystemEvents.find(
      (SystemEvent: SystemEvent) =>
        SystemEvent.stable && SystemEvent.stable.id === stableId
    );
  },
  getByHorseId: (state) => (horseId) => {
    return state.SystemEvents.find(
      (SystemEvent: SystemEvent) =>
        SystemEvent.horse && SystemEvent.horse.id === horseId
    );
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  add(state: S, systemEvent: SystemEvent): void;
  delete(state: S, id: string): boolean;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    // acquire initial state
    Object.assign(state, initialState());
  },
  add(state, systemEvent) {
    state.SystemEvents.push(systemEvent);
  },
  delete(state, id) {
    const index = state.SystemEvents.findIndex(
      (eventIndex) => eventIndex.id === id
    );
    if (index >= 0) {
      state.SystemEvents.splice(index, 1);
      return true;
    }
    return false;
  }
};

// actions
export interface Actions {
  loadAll(
    { commit, dispatch }: ActionContext<State, RootState>,
    spaceId: string
  ): void;

  checkEvent(
    { commit, dispatch }: ActionContext<State, RootState>,
    event: SystemEvent
  ): void;
}

const actions: ActionTree<State, RootState> & Actions = {
  loadAll({ commit, dispatch }, spaceId) {
    loadSystemEventBySpace(spaceId)
      .then((events) => {
        if (events) {
          events.forEach((event) => {
            commit('add', event);
            dispatch('checkEvent', event);
          });
        }
      })
      .catch(() => {
        //quite error?
      });
  },
  checkEvent({ commit, dispatch }, event) {
    if (dayjs(event.end).diff(dayjs(new Date()), 'minute') <= 10) {
      switch (event.action) {
        case FOOD_UPDATE_FOOD_ITEM: {
          if (event.actionData) {
            updateFoodRemoveSupplement(
              event.actionData.foodId,
              event.actionData.foodItemId,
              event.actionData.horseId,
              event.actionData.notificationId,
              event.id
            )
              .then((res) => {
                commit('FoodModule/updateCurrent', res.diet, { root: true });
                dispatch(
                  'FeedModule/addFeedInHorse',
                  {
                    horseId: res.feed.horse?.id,
                    feed: res.feed
                  },
                  { root: true }
                ).catch((error) => Vue.prototype.$rollbar.error(error));
                commit(
                  'HorseModule/deleteNotification',
                  {
                    horseId: event.actionData?.horseId,
                    notificationId: event.actionData?.notificationId,
                    module: null
                  },
                  { root: true }
                );
                commit('delete', event.id);
              })
              .catch((error) => {
                Vue.prototype.$rollbar.error(
                  `Action UPDATE.FOOD was not executed: ${error}`
                );
              });
          } else {
            Vue.prototype.$rollbar.error(
              'Action UPDATE.FOOD need to have actionData'
            );
          }
          break;
        }
        default: {
          Vue.prototype.$rollbar.error(`Action not defined: ${event.action}`);
        }
      }
    }
  }
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const SystemEventModule: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
