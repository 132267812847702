import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { MODULE_TYPES } from '@/store/constants';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { i18n } from './i18n';
import Rollbar from 'rollbar';
import VueApexCharts from 'vue-apexcharts';
//Internal components
import BtnIconTooltip from '@/components/BtnIconTooltip.vue';
import TawkPlugin from '@/plugins/tawk';
import './registerServiceWorker';

dayjs.locale(i18n.locale);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN || '',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.VUE_APP_PLATFORM_ENV || 'unknown'
  }
});

Vue.use(TawkPlugin, {
  tawkSrc: 'https://embed.tawk.to/60f82c37649e0a0a5ccd481c/1fb4mpehe'
});
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.component('BtnIconTooltip', BtnIconTooltip);

Vue.config.productionTip = false;

//global variables, ready only
Vue.prototype.$moduleTypes = MODULE_TYPES;
Vue.prototype.$dayjs = dayjs;

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
