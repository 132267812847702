import Vue from 'vue';
import VueRouter, { NavigationGuardNext } from 'vue-router';
import store from '@/store/index';
const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue');
const Stable = () =>
  import(/* webpackChunkName: "stable" */ '../views/Stable.vue');
const Calendar = () =>
  import(/* webpackChunkName: "calendar" */ '../views/Calendar.vue');
const Spaces = () =>
  import(/* webpackChunkName: "spaces" */ '../views/Spaces.vue');
const Horses = () =>
  import(/* webpackChunkName: "horses" */ '../views/Horses.vue');
//modules
const Health = () =>
  import(/* webpackChunkName: "modules" */ '../views/Horse/Health.vue');
const Food = () =>
  import(/* webpackChunkName: "modules" */ '../views/Horse/Food.vue');
const HorseShoe = () =>
  import(/* webpackChunkName: "modules" */ '../views/Horse/HorseShoe.vue');
const Sport = () =>
  import(/* webpackChunkName: "modules" */ '../views/Horse/Sport.vue');
//user
const User = () => import(/* webpackChunkName: "user" */ '../views/User.vue');
//horse pages
const HorseProfile = () =>
  import(/* webpackChunkName: "horse" */ '../views/Horse/HorseProfile.vue');
const HorseGeneral = () =>
  import(/* webpackChunkName: "horse" */ '../views/Horse/HorseGeneral.vue');
//no login pages
const Login = () =>
  import(/* webpackChunkName: "login" */ '../views/Login.vue');
const EmailConfirmation = () =>
  import(/* webpackChunkName: "login" */ '../views/EmailConfirmation.vue');
const ResetPassword = () =>
  import(/* webpackChunkName: "login" */ '../views/ResetPassword.vue');
import { i18n } from '@/i18n';
import Tawk from '@/plugins/tawk';

Vue.use(VueRouter);

export const LOGIN = 'Login';
export const EMAIL_CONFIRMATION = 'Email Confirmation';
export const RESET_PASSWORD = 'Reset Password';
export const USER = 'User';
export const SPACES = 'Spaces';
export const SPACES_HOME = 'Home';
export const HORSES = 'Horses';
export const HORSE = 'Horse General';
export const HORSE_PROFILE = 'Horse Profile';
export const HEALTH = 'Health';
export const FOOD = 'Food';
export const HORSESHOE = 'Horseshoe';
export const SPORT = 'Sport';
export const STABLE = 'Stable';
export const CALENDAR = 'Calendar';

const routes = [
  {
    path: '/login',
    name: LOGIN,
    component: Login,
    meta: { requiresAuth: false, title: i18n.t('pages.login') }
  },
  {
    path: '/confirm-email/:code',
    name: EMAIL_CONFIRMATION,
    component: EmailConfirmation,
    meta: { requiresAuth: false, title: i18n.t('pages.confirm-email') }
  },
  {
    path: '/reset-password/:code',
    name: RESET_PASSWORD,
    component: ResetPassword,
    meta: { requiresAuth: false, title: i18n.t('pages.reset-password') }
  },
  {
    path: '/user/',
    name: USER,
    component: User,
    meta: { requiresAuth: true, title: i18n.t('pages.user') }
  },
  {
    path: '/',
    name: SPACES,
    component: Spaces,
    meta: { requiresAuth: true, title: i18n.t('pages.spaces') }
  },
  {
    path: '/:spaceId',
    name: SPACES_HOME,
    component: Home,
    meta: { requiresAuth: true, title: i18n.t('pages.space-home') }
  },
  {
    path: '/:spaceId/horses',
    name: HORSES,
    component: Horses,
    meta: { requiresAuth: true, title: i18n.t('pages.horses') }
  },
  {
    path: '/:spaceId/horse/:horseId',
    name: HORSE,
    component: HorseGeneral,
    props: true,
    meta: { requiresAuth: true, title: i18n.t('pages.horse') }
  },
  {
    path: '/:spaceId/horse/:horseId/profile',
    name: HORSE_PROFILE,
    component: HorseProfile,
    props: true,
    meta: { requiresAuth: true, title: i18n.t('pages.horse-profile') }
  },
  {
    path: '/:spaceId/horse/:horseId/health',
    name: HEALTH,
    component: Health,
    meta: { requiresAuth: true, title: i18n.t('pages.health') }
  },
  {
    path: '/:spaceId/horse/:horseId/food',
    name: FOOD,
    component: Food,
    meta: { requiresAuth: true, title: i18n.t('pages.food') }
  },
  {
    path: '/:spaceId/horse/:horseId/horseshoe',
    name: HORSESHOE,
    component: HorseShoe,
    meta: { requiresAuth: true, title: i18n.t('pages.horseshoe') }
  },
  {
    path: '/:spaceId/horse/:horseId/sport',
    name: SPORT,
    component: Sport,
    meta: { requiresAuth: true, title: i18n.t('pages.sport') }
  },
  {
    path: '/:spaceId/stable/:stableId',
    name: STABLE,
    component: Stable,
    meta: { requiresAuth: true, title: i18n.t('pages.stable') }
  },
  {
    path: '/:spaceId/calendar',
    name: CALENDAR,
    component: Calendar,
    meta: { requiresAuth: true, title: i18n.t('pages.calendar') }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

function loadSpace(spaceId: string, next: NavigationGuardNext) {
  const currentSpace = store.getters['UserModule/getSpace'];
  if (!currentSpace || currentSpace.id !== spaceId) {
    store
      .dispatch('SpaceModule/loadSpaceData', spaceId)
      .then(() => {
        document.title = 'Beyond Horse - ' + i18n.t('pages.space-home');
        Tawk.setAttributes({ key: 'Space Id', value: spaceId });
        next();
      })
      .catch((error) => {
        document.title = 'Beyond Horse - ' + i18n.t('pages.spaces');
        Tawk.setAttributes({ key: 'Space Id', value: '-' });
        next({
          name: SPACES,
          query: { error: error.code.toString() }
        });
      });
  } else {
    //TODO check fauna if the space exist and user has access but was not loaded at vuex before
    document.title = 'Beyond Horse - ' + i18n.t('pages.space-home');
    next();
  }
}

router.beforeEach((to, from, next) => {
  const authRequired = to.meta?.requiresAuth;
  const user = store.getters['UserModule/getUser'];
  if (authRequired && !user) {
    document.title = 'Beyond Horse - ' + i18n.t('pages.login');
    next({
      name: LOGIN,
      query: { redirect: to.fullPath }
    });
  } else {
    //check if the URL space is the currently loaded one
    if (to.params && to.params.spaceId && to.name && to.name === SPACES_HOME) {
      const spaceId = to.params.spaceId;
      loadSpace(spaceId, next);
    } else if (to.name && to.name === LOGIN && user) {
      document.title = 'Beyond Horse - ' + i18n.t('pages.spaces');
      Tawk.setAttributes({ key: 'Space Id', value: '-' });
      next({
        name: SPACES
      });
    } else {
      if (to.name && to.name === SPACES) {
        Tawk.setAttributes({ key: 'Space Id', value: '-' });
      }
      document.title = 'Beyond Horse - ' + to.meta?.title;
      next();
    }
  }
});

export default router;
