import Vue from 'vue';
import { logout } from '@/helpers/utils';
import { LOGIN } from '@/router';

interface Data {
  refreshing: boolean;
  registration: ServiceWorkerRegistration | null;
  updateExists: boolean;
}

export interface Method {
  updateAvailable(event: CustomEvent<ServiceWorkerRegistration>): void;
  refreshApp(): void;
}
export default Vue.extend<
  Data,
  Method,
  Record<string, never>,
  Record<string, never>
>({
  data: () => ({
    refreshing: false,
    registration: null,
    updateExists: false
  }),
  created() {
    document.addEventListener(
      'swUpdated',
      (event) => {
        this.updateAvailable(event as CustomEvent<ServiceWorkerRegistration>);
      },
      {
        once: true
      }
    );
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      logout();
      window.location.reload();
    });
  },

  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      if (this.$router.currentRoute.name === LOGIN) {
        this.refreshApp();
      } else {
        this.updateExists = true;
      }
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  }
});
