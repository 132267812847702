import _Vue from 'vue';
import TawkType from 'tawk';

declare global {
  interface Window {
    Tawk_API: TawkType;
  }
}
const Tawk = {
  updateChatUser: (name: string, email: string): void => {
    if (!name && !email) return;
    window.Tawk_API.setAttributes({
      name: name,
      email: email
    });
  },
  setAttributes: (obj: { key: string; value: string }): void => {
    if (!obj.key && !obj.value) return;
    window.Tawk_API.setAttributes({ [obj.key]: obj.value });
  },
  endChat: (): void => window.Tawk_API.endChat(),
  hideWidget: (): void => window.Tawk_API.hideWidget(),
  showWidget: (): void => window.Tawk_API.showWidget(),
  toggle: (): void => window.Tawk_API.toggle(),
  maximize: (): void => window.Tawk_API.maximize(),
  minimize: (): void => window.Tawk_API.minimize(),
  toggleVisibility: (): void => window.Tawk_API.toggleVisibility(),
  isInit: (): boolean => !!window.Tawk_API,
  install(Vue: typeof _Vue, options: { tawkSrc: string }): void {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = options.tawkSrc;
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s1.setAttribute('rel', 'preconnect');
    s0.parentNode?.insertBefore(s1, s0);
    window.Tawk_API = this;
    Vue.prototype.$Tawk = this;
  }
};

export default Tawk;
