import { TranslateResult } from 'vue-i18n';
import { mdiBaguette, mdiBarley, mdiBottleTonic } from '@mdi/js';

export type DataType = Record<string, { value: string; code: boolean }> | null;

export interface LocalizedSelectItem {
  label: TranslateResult;
  value: string;
}

export interface FoodTypes {
  RATION: FoodItemType;
  SUPPLEMENT: FoodItemType;
  VOLUMINOUS: FoodItemType;
}

export interface FoodItemType {
  name: string;
  i18nCodeName: string;
  i18nCodeDetail?: string;
  icon: string;
}

export const FOOD_TYPES: FoodTypes = Object.freeze({
  RATION: {
    name: 'ration',
    i18nCodeName: 'food-batch-card.categories.ration',
    i18nCodeDetail: 'food-module-screen.guarantee-levels',
    icon: mdiBaguette
  },
  SUPPLEMENT: {
    name: 'supplement',
    i18nCodeName: 'food-batch-card.categories.supplement',
    icon: mdiBottleTonic
  },
  VOLUMINOUS: {
    name: 'voluminous',
    i18nCodeName: 'food-batch-card.categories.voluminous',
    i18nCodeDetail: 'food-module-screen.bromatological-analysis',
    icon: mdiBarley
  }
});
