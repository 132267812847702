import {
  ActionContext,
  ActionTree,
  CommitOptions,
  DispatchOptions,
  GetterTree,
  Module,
  MutationTree,
  Store as VuexStore
} from "vuex";

import { State as RootState } from "@/store";
import {
  createEIAForHorse,
  createGlandersForHorse,
  createHealthReportForHorse,
  createVaccineForHorse,
  createVermifugeForHorse,
  dbEditHealthReport,
  loadEIA,
  loadGlanders,
  loadHealthReports,
  loadVaccines,
  loadVermifuges
} from "@/helpers/data/fauna-queries";
import Vue from "vue";
import { ERROR_LIST, ErrorItem } from "@/helpers/errors";
import { HorseItem } from "@/views/Horses.vue";
import { upload } from "@/helpers/image/imagekit-helpers";
import dayjs from "dayjs";

export interface UploadedFile {
  fileName?: string;
  filePath?: string;
  fileType?: string;
  fileId?: string;
  fileGivenName?: string;
  fileUrl: string;
  uploadedDate?: string;
}

export interface ExamGlandersEIA {
  id: string;
  horseId?: string;
  dateStart: string;
  dateEnd: string;
  laboratory: string;
  examNumber: string;
  doctor: Doctor;
}

export interface Vaccine {
  id: string;
  horseId?: string;
  dateStart: string;
  dateEnd: string;
  product: string;
  batchNumber: string;
  protectedAgainst: string[];
  doctor: Doctor;
}

export interface Doctor {
  name: string;
  phone: string;
}

export interface HealthReport {
  id: string;
  horseId?: string;
  type: string;
  dateStart: string;
  dateEnd: string | null;
  lastEntry: HealthEntry;
  entries: HealthEntry[];
  files?: UploadedFile[];
}

export interface Vermifuge {
  id: string;
  horseId?: string;
  dateStart: string;
  dateEnd: string;
  drug: string;
  activePrinciple: string[];
  doctor: Doctor;
}

export interface Doctor {
  name: string;
  phone: string;
}

export interface HealthEntry {
  dateCreated: string;
  dateStart: string;
  anamnesis: string;
  clinicalExam: MainExam;
  treatments: Treatment[];
  conclusion: string;
  doctorName: string;
  doctorPhone: string;
}

export interface MainExam {
  description: string;
  exams: Exam[];
}

export interface Exam {
  description: string;
  docType: string;
  docUrl: string;
}

export interface Treatment {
  use: string;
  local?: string;
  localDescription?: string;
  name?: string;
  quantity?: number | null;
  qtdUnit?: string;
  other_unit?: string;
  eye?: string;
  useDilute?: boolean;
  dilute?: string;
  frequency: number | null;
  freqPeriod: string;
  duration: string;
  dateStart: string;
  observation: string;
}

export interface HealthReportData {
  current: HealthReport[];
  history: HealthReport[];
}

export interface VermifugeData {
  all: Vermifuge[];
}

export interface VaccineData {
  all: Vaccine[];
}

export interface ExamGlandersEIAData {
  all: ExamGlandersEIA[];
}

export interface State {
  vaccines: Record<string, VaccineData>;
  healthReports: Record<string, HealthReportData>;
  vermifuges: Record<string, VermifugeData>;
  eia: Record<string, ExamGlandersEIAData>;
  glanders: Record<string, ExamGlandersEIAData>;
}

function initialState() {
  return {
    healthReports: {},
    vermifuges: {},
    vaccines: {},
    eia: {},
    glanders: {}
  };
}

// getters
export type Getters = {
  getByHorseId(state: State): (horseId: string) => HealthReportData | null;
  getHistoryByHorseId(state: State): (horseId: string) => HealthReport[] | null;
  getCurrentByHorseId(state: State): (horseId: string) => HealthReport[] | null;
  getAllByHorseId(state: State): (horseId: string) => HealthReport[] | null;
  getAllVermifugesByHorse(
    state: State
  ): (horseId: string) => Vermifuge[] | null;
  getAllVaccinesByHorse(state: State): (horseId: string) => Vaccine[] | null;
  getAllEIAByHorse(state: State): (horseId: string) => ExamGlandersEIA[] | null;
  getAllGlandersByHorse(
    state: State
  ): (horseId: string) => ExamGlandersEIA[] | null;
};

const getters: GetterTree<State, RootState> & Getters = {
  getAllVermifugesByHorse: (state) => (horseId) => {
    let vermifuges: Vermifuge[] = [];
    if (horseId in state.vermifuges) {
      vermifuges = state.vermifuges[horseId].all;
    }
    return vermifuges;
  },
  getAllVaccinesByHorse: (state) => (horseId) => {
    let vaccines: Vaccine[] = [];
    if (horseId in state.vermifuges) {
      vaccines = state.vaccines[horseId].all;
    }
    return vaccines;
  },
  getAllEIAByHorse: (state) => (horseId) => {
    let eia: ExamGlandersEIA[] = [];
    if (horseId in state.eia) {
      eia = state.eia[horseId].all;
    }
    eia.sort(function (a, b) {
      const timeB = new Date(b.dateEnd);
      const timeA = new Date(a.dateEnd);
      return timeB.getTime() - timeA.getTime();
    });
    return eia;
  },
  getAllGlandersByHorse: (state) => (horseId) => {
    let glanders: ExamGlandersEIA[] = [];
    if (horseId in state.glanders) {
      glanders = state.glanders[horseId].all;
    }
    glanders.sort(function (a, b) {
      const timeB = new Date(b.dateEnd);
      const timeA = new Date(a.dateEnd);
      return timeB.getTime() - timeA.getTime();
    });
    return glanders;
  },
  getByHorseId: (state) => (horseId) => {
    let healthReport = null;
    if (horseId in state.healthReports)
      healthReport = state.healthReports[horseId];
    return healthReport;
  },
  getHistoryByHorseId: (state) => (horseId) => {
    let healthReport = null;
    if (horseId in state.healthReports)
      healthReport = state.healthReports[horseId].history;
    return healthReport;
  },
  getCurrentByHorseId: (state) => (horseId) => {
    let healthReport = null;
    if (horseId in state.healthReports)
      healthReport = state.healthReports[horseId].current;
    return healthReport;
  },
  getAllByHorseId: (state) => (horseId) => {
    let healthReport: HealthReport[] = [];
    if (horseId in state.healthReports) {
      healthReport = state.healthReports[horseId].current.concat(
        state.healthReports[horseId].history
      );
    }
    return healthReport.length ? healthReport : null;
  }
};

// mutations
export type Mutations<S = State> = {
  reset(state: S): void;
  create(state: S, healthReport: HealthReport): void;
  createVermifuge(state: S, vermifuge: Vermifuge): void;
  createVaccine(state: S, vaccine: Vaccine): void;
  createEIA(state: S, eia: ExamGlandersEIA): void;
  createGlanders(state: S, glanders: ExamGlandersEIA): void;
  update(state: S, healthReport: HealthReport): void;
};

const mutations: MutationTree<State> & Mutations = {
  reset(state) {
    Object.assign(state, initialState());
  },
  create(state, healthReport) {
    if (healthReport.horseId) {
      if (!state.healthReports[healthReport.horseId]) {
        Vue.set(state.healthReports, healthReport.horseId, {
          current: [],
          history: []
        });
      }
      if (healthReport.dateEnd) {
        state.healthReports[healthReport.horseId].history.push(healthReport);
      } else {
        state.healthReports[healthReport.horseId].current.push(healthReport);
      }
    }
  },
  createEIA(state, eia) {
    if (eia.horseId) {
      if (!state.eia[eia.horseId]) {
        Vue.set(state.eia, eia.horseId, {
          all: []
        });
      }
      state.eia[eia.horseId].all.push(eia);
    }
  },
  createGlanders(state, glanders) {
    if (glanders.horseId) {
      if (!state.glanders[glanders.horseId]) {
        Vue.set(state.glanders, glanders.horseId, {
          all: []
        });
      }
      state.glanders[glanders.horseId].all.push(glanders);
    }
  },
  createVaccine(state, vaccine) {
    if (vaccine.horseId) {
      if (!state.vaccines[vaccine.horseId]) {
        Vue.set(state.vaccines, vaccine.horseId, {
          all: []
        });
      }
      state.vaccines[vaccine.horseId].all.push(vaccine);
    }
  },
  createVermifuge(state, vermifuge) {
    if (vermifuge.horseId) {
      if (!state.vermifuges[vermifuge.horseId]) {
        Vue.set(state.vermifuges, vermifuge.horseId, {
          all: []
        });
      }
      state.vermifuges[vermifuge.horseId].all.push(vermifuge);
    }
  },
  update(state, healthReport) {
    if (healthReport.horseId && healthReport.horseId in state.healthReports) {
      const current = state.healthReports[healthReport.horseId].current;
      if (!current) {
        //TODO Report not found, add error
        return;
      }
      const healthReportIndex = current.findIndex(
        (hr) => hr.id === healthReport.id
      );
      if (healthReportIndex >= 0) {
        if (healthReport.dateEnd) {
          current.splice(healthReportIndex, 1);
          let history = state.healthReports[healthReport.horseId].history;
          if (!history) {
            history = [];
          }
          history.push(healthReport);
        } else {
          current[healthReportIndex] = healthReport;
        }
      }
    }
  }
};

// actions
export interface Actions {
  addHealthReport(
    { dispatch }: ActionContext<State, RootState>,
    payload: { horseId: string; healthReport: HealthReport }
  ): Promise<void>;

  addVermifuge(
    { dispatch }: ActionContext<State, RootState>,
    payload: { horseId: string; vermifuge: Vermifuge }
  ): Promise<void>;

  addVaccine(
    { dispatch }: ActionContext<State, RootState>,
    payload: { horseId: string; vaccine: Vaccine }
  ): Promise<void>;

  addEIA(
    { dispatch }: ActionContext<State, RootState>,
    payload: { horseId: string; eia: ExamGlandersEIA }
  ): Promise<void>;

  addGlanders(
    { dispatch }: ActionContext<State, RootState>,
    payload: { horseId: string; glanders: ExamGlandersEIA }
  ): Promise<void>;

  addHealthReportToHorses(
    { commit, dispatch }: ActionContext<State, RootState>,
    payload: { horsesId: string[]; healthReport: HealthReport }
  ): Promise<void>;

  addVermifugeToHorses(
    { commit, dispatch }: ActionContext<State, RootState>,
    payload: { horsesId: string[]; vermifuge: Vermifuge }
  ): Promise<void>;

  addVaccineToHorses(
    { commit, dispatch }: ActionContext<State, RootState>,
    payload: { horsesId: string[]; vaccine: Vaccine }
  ): Promise<void>;

  addEIAToHorses(
    { commit, dispatch }: ActionContext<State, RootState>,
    payload: { horses: HorseItem[]; eia: ExamGlandersEIA }
  ): Promise<void>;

  addGlandersToHorses(
    { commit, dispatch }: ActionContext<State, RootState>,
    payload: { horses: HorseItem[]; glanders: ExamGlandersEIA }
  ): Promise<void>;

  loadHealthReportFromHorse(
    { getters, commit }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<HealthReport[] | null>;

  loadVermifugeFromHorse(
    { getters, commit }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<Vermifuge[] | null>;

  loadVaccinesFromHorse(
    { getters, commit }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<Vaccine[] | null>;

  loadEIAFromHorse(
    { getters, commit }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<ExamGlandersEIA[] | null>;

  loadGlandersFromHorse(
    { getters, commit }: ActionContext<State, RootState>,
    horseId: string
  ): Promise<ExamGlandersEIA[] | null>;

  updateHealthReport(
    { commit }: ActionContext<State, RootState>,
    payload: { healthReport: HealthReport }
  ): Promise<HealthReport | ErrorItem>;

  uploadHealthReportFile(
    { commit }: ActionContext<State, RootState>,
    payload: {
      healthReport: HealthReport;
      fileToUpload: File;
      fileName: string;
    }
  ): Promise<HealthReport | ErrorItem>;
}

const actions: ActionTree<State, RootState> & Actions = {
  addHealthReportToHorses({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const addToHorses: Promise<void>[] = [];
      payload.horsesId.forEach((horseId) => {
        addToHorses.push(
          dispatch('loadHealthReportFromHorse', horseId).then(() =>
            dispatch('addHealthReport', {
              horseId: horseId,
              healthReport: payload.healthReport
            })
          )
        );
      });
      Promise.all(addToHorses)
        .then(() => resolve())
        .catch((error) => {
          reject(error);
        });
    });
  },
  addVermifugeToHorses({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const addToHorses: Promise<void>[] = [];
      payload.horsesId.forEach((horseId) => {
        addToHorses.push(
          dispatch('loadVermifugeFromHorse', horseId).then(() =>
            dispatch('addVermifuge', {
              horseId: horseId,
              vermifuge: payload.vermifuge
            })
          )
        );
      });
      Promise.all(addToHorses)
        .then(() => resolve())
        .catch((error) => {
          reject(error);
        });
    });
  },
  addGlandersToHorses({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const addToGlanders: Promise<void>[] = [];
      payload.horses.forEach((itemHorse) => {
        const localGlanders = payload.glanders;
        if (itemHorse.additionalField) {
          localGlanders.examNumber = itemHorse.additionalField;
        }
        addToGlanders.push(
          dispatch('loadGlandersFromHorse', itemHorse.horse.id).then(() =>
            dispatch('addGlanders', {
              horseId: itemHorse.horse.id,
              glanders: localGlanders
            })
          )
        );
        Promise.all(addToGlanders)
          .then(() => resolve())
          .catch((error) => {
            reject(error);
          });
      });
    });
  },
  addEIAToHorses({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const addToEIA: Promise<void>[] = [];
      payload.horses.forEach((itemHorse) => {
        const localEIA = payload.eia;
        if (itemHorse.additionalField) {
          localEIA.examNumber = itemHorse.additionalField;
        }
        addToEIA.push(
          dispatch('loadEIAFromHorse', itemHorse.horse.id).then(() =>
            dispatch('addEIA', {
              horseId: itemHorse.horse.id,
              eia: localEIA
            })
          )
        );
      });
      Promise.all(addToEIA)
        .then(() => resolve())
        .catch((error) => {
          reject(error);
        });
    });
  },
  addVaccineToHorses({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const addToHorses: Promise<void>[] = [];
      payload.horsesId.forEach((horseId) => {
        addToHorses.push(
          dispatch('loadVaccinesFromHorse', horseId).then(() =>
            dispatch('addVaccine', {
              horseId: horseId,
              vaccine: payload.vaccine
            })
          )
        );
      });
      Promise.all(addToHorses)
        .then(() => resolve())
        .catch((error) => {
          reject(error);
        });
    });
  },
  addVermifuge({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      createVermifugeForHorse(payload.horseId, payload.vermifuge).then(
        (obj) => {
          commit('createVermifuge', obj.vermifuge);
          dispatch(
            'FeedModule/addFeedInHorse',
            {
              horseId: obj.feed.horse?.id,
              feed: obj.feed
            },
            { root: true }
          ).catch((error) => {
            Vue.prototype.$rollbar.error(error);
            reject(error);
          });
          commit(
            'HorseModule/addNotification',
            {
              horseId: obj.notification.horseId,
              notification: obj.notification,
              module: obj.notification.category
            },
            { root: true }
          );
          resolve();
        }
      );
    });
  },
  addVaccine({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      createVaccineForHorse(payload.horseId, payload.vaccine).then((obj) => {
        commit('createVaccine', obj.vaccine);
        dispatch(
          'FeedModule/addFeedInHorse',
          {
            horseId: obj.feed.horse?.id,
            feed: obj.feed
          },
          { root: true }
        ).catch((error) => {
          Vue.prototype.$rollbar.error(error);
          reject(error);
        });
        commit(
          'HorseModule/addNotification',
          {
            horseId: obj.notification.horseId,
            notification: obj.notification,
            module: obj.notification.category
          },
          { root: true }
        );
        resolve();
      });
    });
  },
  addEIA({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      createEIAForHorse(payload.horseId, payload.eia).then((obj) => {
        commit('createEIA', obj.eia);
        dispatch(
          'FeedModule/addFeedInHorse',
          {
            horseId: obj.feed.horse?.id,
            feed: obj.feed
          },
          { root: true }
        ).catch((error) => {
          Vue.prototype.$rollbar.error(error);
          reject(error);
        });
        commit(
          'HorseModule/addNotification',
          {
            horseId: obj.notification.horseId,
            notification: obj.notification,
            module: obj.notification.category
          },
          { root: true }
        );
        resolve();
      });
    });
  },
  addGlanders({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      createGlandersForHorse(payload.horseId, payload.glanders).then((obj) => {
        commit('createGlanders', obj.glanders);
        dispatch(
          'FeedModule/addFeedInHorse',
          {
            horseId: obj.feed.horse?.id,
            feed: obj.feed
          },
          { root: true }
        ).catch((error) => {
          Vue.prototype.$rollbar.error(error);
          reject(error);
        });
        commit(
          'HorseModule/addNotification',
          {
            horseId: obj.notification.horseId,
            notification: obj.notification,
            module: obj.notification.category
          },
          { root: true }
        );
        resolve();
      });
    });
  },
  addHealthReport({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      createHealthReportForHorse(payload.horseId, payload.healthReport).then(
        (obj) => {
          commit('create', obj.healthReport);
          dispatch(
            'FeedModule/addFeedInHorse',
            {
              horseId: obj.feed.horse?.id,
              feed: obj.feed
            },
            { root: true }
          ).catch((error) => {
            Vue.prototype.$rollbar.error(error);
            reject(error);
          });
          resolve();
        }
      );
    });
  },
  loadHealthReportFromHorse({ getters, commit }, horseId) {
    return new Promise((resolve, reject) => {
      const allHealthReports = getters.getAllByHorseId(horseId);
      if (!allHealthReports) {
        loadHealthReports(horseId)
          .then((healthReports) => {
            if (healthReports && healthReports.length) {
              healthReports.forEach((hr) => {
                commit('create', hr);
              });
            }
            resolve(healthReports);
          })
          .catch(() => {
            reject(null);
          });
      } else {
        resolve(allHealthReports);
      }
    });
  },
  loadVermifugeFromHorse({ getters, commit }, horseId) {
    return new Promise((resolve, reject) => {
      const allVermifuges = getters.getAllVermifugesByHorse(horseId);
      if (!allVermifuges || allVermifuges.length === 0) {
        loadVermifuges(horseId)
          .then((vermifuges) => {
            if (vermifuges && vermifuges.length) {
              vermifuges.forEach((vermifuge) => {
                commit('createVermifuge', vermifuge);
              });
            }
            resolve(vermifuges);
          })
          .catch(() => {
            reject(null);
          });
      } else {
        resolve(allVermifuges);
      }
    });
  },
  loadVaccinesFromHorse({ getters, commit }, horseId) {
    return new Promise((resolve, reject) => {
      const allVaccines = getters.getAllVaccinesByHorse(horseId);
      if (!allVaccines || allVaccines.length === 0) {
        loadVaccines(horseId)
          .then((vaccines) => {
            if (vaccines && vaccines.length) {
              vaccines.forEach((vaccine) => {
                commit('createVaccine', vaccine);
              });
            }
            resolve(vaccines);
          })
          .catch(() => {
            reject(null);
          });
      } else {
        resolve(allVaccines);
      }
    });
  },
  loadEIAFromHorse({ getters, commit }, horseId) {
    return new Promise((resolve, reject) => {
      const allEIA = getters.getAllEIAByHorse(horseId);
      if (!allEIA || allEIA.length === 0) {
        loadEIA(horseId)
          .then((eiaList) => {
            if (eiaList && eiaList.length) {
              eiaList.sort(function (a, b) {
                const timeB = new Date(b.dateEnd);
                const timeA = new Date(a.dateEnd);
                return timeB.getTime() - timeA.getTime();
              });
              eiaList.forEach((eia) => {
                commit('createEIA', eia);
              });
            }
            resolve(eiaList);
          })
          .catch(() => {
            reject(null);
          });
      } else {
        resolve(allEIA);
      }
    });
  },
  loadGlandersFromHorse({ getters, commit }, horseId) {
    return new Promise((resolve, reject) => {
      const allGlanders = getters.getAllGlandersByHorse(horseId);
      if (!allGlanders || allGlanders.length === 0) {
        loadGlanders(horseId)
          .then((glandersList) => {
            if (glandersList && glandersList.length) {
              glandersList.sort(function (a, b) {
                const timeB = new Date(b.dateEnd);
                const timeA = new Date(a.dateEnd);
                return timeB.getTime() - timeA.getTime();
              });
              glandersList.forEach((glanders) => {
                commit('createGlanders', glanders);
              });
            }
            resolve(glandersList);
          })
          .catch(() => {
            reject(null);
          });
      } else {
        resolve(allGlanders);
      }
    });
  },
  updateHealthReport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dbEditHealthReport(payload.healthReport)
        .then(() => {
          const newHealthReport = Object.assign({}, payload.healthReport);
          commit('update', newHealthReport);
          resolve(newHealthReport);
        })
        .catch((error) => {
          Vue.prototype.$rollbar.error(error);
          reject(ERROR_LIST.HEALTH_REPORT_UPDATE_FAIL);
        });
    });
  },
  uploadHealthReportFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.fileToUpload) {
        upload(payload.fileToUpload, {
          tags: 'horse-health-report',
          folder: payload.healthReport.horseId,
          fileName: payload.fileName
        })
          .then((result) => {
            if (!Array.isArray(payload.healthReport.files)) {
              payload.healthReport.files = [];
            }
            const newFile: UploadedFile = {
              fileName: result.name,
              filePath: result.filePath,
              fileUrl: result.url,
              fileId: result.fileId,
              fileType: result.fileType,
              fileGivenName: payload.fileName,
              uploadedDate: dayjs().toISOString()
            };
            payload.healthReport.files.push(newFile);
            dbEditHealthReport(payload.healthReport)
              .then(() => {
                const newHealthReport = Object.assign({}, payload.healthReport);
                commit('update', newHealthReport);
                resolve(newHealthReport);
              })
              .catch((error) => {
                Vue.prototype.$rollbar.error(error);
                reject(ERROR_LIST.HEALTH_REPORT_UPDATE_FAIL);
              });
          })
          .catch((error) => {
            Vue.prototype.$rollbar.error(error);
            reject(ERROR_LIST.HORSE_CREATE_IMAGE_FAIL);
          });
      } else {
        reject(ERROR_LIST.HEALTH_REPORT_UPDATE_FAIL);
      }
    });
  }
};
//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const HealthModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
